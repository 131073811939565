import React, { useEffect, useState, useRef,Fragment } from 'react'
import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'
import Row from '../../MDBproComponents/Row/Row'
import Link from '../../MDBproComponents/Link/Link'
import { useCookies } from 'react-cookie';
import InputNumber from '../../MDBproComponents/InputNumber';
import Button from '../../MDBproComponents/Button';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
const Food = (props) => {
    const { t, i18n } = useTranslation();
    const numberInput = useRef();
    let [optionsModal, setOptionsModal] = useState(false)
    const [cookies, setCookie] = useCookies();
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [additionalPrice,setAdditionalPrice]=useState(0);
    const [jsonOptions,setJsonOptions]=useState(null)
    let [isOpen, setIsOpen] = useState(false);
    const [foodOptions, setFoodOptions] = useState([]);
    useEffect(() => {
        if (cookies.CartItems.find(x => x.id == props.foodID)) {
            setCount(cookies.CartItems.find(x => x.id == props.foodID).count)
        }
        setTotal(props.price * count)
    })

    const setOptionItem=(e,type)=>{
        let $this=e.target;
        let id='';
        let color="";
        if(type=="add"){
            color="text-success"
        }
        if(type=="subtract"){
            color="text-danger"
        }
        if(type=="selective"){
            color="text-dark"
            id=$this.getAttribute("data-id")
            console.log(id)
            let others=document.querySelectorAll('.optionItem[data-id="'+id+'"]');
            for (let i = 0; i < others.length; i++) {
                others[i].classList.remove("selected")
                others[i].classList.remove(color,"fa-dot-circle")
                others[i].classList.add("text-black","fa-circle")
                
            }
        }
        if($this){
            if($this.classList.contains("selected")){
             $this.classList.remove("selected")
             $this.classList.remove(color,"fa-dot-circle")
             $this.classList.add("text-black","fa-circle")
            }else{
             $this.classList.add("selected")
             $this.classList.remove("text-black","fa-circle")
             $this.classList.add(color,"fa-dot-circle")
            }
        }
      
    }

    const openAccordion=()=>{

        let item=document.getElementsByClassName("accordion-888")[0]
if(item){
    item.classList.toggle("active");
    let panel =  item.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }

}
        
    }

    const accordion=(e)=>{
        let $this=e.target;
        $this.classList.toggle("active");

        let panel =  $this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }

useEffect(() => {
if((props.foodOptions??"").length>0){
    setFoodOptions(JSON.parse(props.foodOptions))
    setTimeout(() => {
        openAccordion()
    }, 2000);
}

}, [])

    function addToCart(item) {
        let jsonCartItems = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')

        if (jsonCartItems.find(x => x.id == item.id)) {
            jsonCartItems.find(x => x.id == item.id).count = jsonCartItems.find(x => x.id == item.id).count + item.count;
            if (jsonCartItems.find(x => x.id == item.id).count <= 0) {
                jsonCartItems = jsonCartItems.filter(function (x) { return x.id !== item.id })
            }
        } else {
            jsonCartItems.push(item)
        }
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setCookie('CartItems', JSON.stringify(jsonCartItems), { path: '/', expires: tomorrow });
        setCount(cookies.CartItems.find(x => x.id == props.foodID).count)
        document.getElementById('spnShoppingCartCount').innerText = jsonCartItems.reduce((sum, x) => sum + x.count, 0);
        document.getElementById('spnShoppingCartPrice').innerText = '₺ ' + parseFloat(jsonCartItems.reduce((sum, x) => sum + x.count * x.price, 0)).toFixed(0);
    }
    const optionsModalToggle = () => {
        setOptionsModal(!optionsModal)
    }

    const saveToCookie=(json,amount)=>{
        let jsonCartItems = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')

        if (jsonCartItems.find(x => x.id == props.foodID)) {
            jsonCartItems.find(x => x.id == props.foodID)["selectedOptions"] = json;
            jsonCartItems.find(x => x.id == props.foodID)["additionalPrice"] = amount;
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            setCookie('CartItems', JSON.stringify(jsonCartItems), { path: '/', expires: tomorrow });
        }
        
    }

    const saveFoodOptions=()=>{
        let json=[];
        let amount=0;
        let foods=document.getElementsByClassName("panel")
        for (let i = 0; i < foods.length; i++) {
            let obj={}
            obj["id"] = i+1;
            let objItems=[];
           let items=foods[i].getElementsByClassName("selected")
           for (let y = 0; y < items.length; y++) {
            let option={};
            option["title"]=items[y].getAttribute("data-title")
            option["price"]=items[y].getAttribute("data-price")
            option["type"]=items[y].getAttribute("data-type")
            objItems.push(option);
          
            if(items[y].getAttribute("data-type")=="subtract"){
                amount-=parseFloat(items[y].getAttribute("data-price"))
            }else{
                amount+=parseFloat(items[y].getAttribute("data-price"))
            }
           }
           obj["items"]=objItems;
           if(objItems.length>0){
               json.push(obj)
           }
        }
        setJsonOptions(JSON.stringify(json))
        setAdditionalPrice(amount)
        setOptionsModal(false)
        saveToCookie(json,amount)
    }
    
    useEffect(() => {
       let json = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')
        if (json.find(x => x.id == props.foodID).selectedOptions){
            setAdditionalPrice(json.find(x => x.id == props.foodID).additionalPrice)
            json=json.find(x => x.id == props.foodID).selectedOptions;
            setJsonOptions(JSON.stringify(json))
        }
    }, [cookies.CartItems])
    

const openModalFoodOptions=()=>{
    let json=JSON.parse('[]')
    if(jsonOptions){
         json=JSON.parse(jsonOptions)
    }else{
             json = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')
        if (json.find(x => x.id == props.foodID).selectedOptions){
            json=json.find(x => x.id == props.foodID).selectedOptions;
        }else{
            json=JSON.parse('[]')
        }
    }
    console.log('12')
    console.log(json)
    if(JSON.stringify(json).length>4){
        setOptionsModal(true)
       
        setTimeout(() => {
            let foods=document.getElementsByClassName("panel")
            for (let i = 0; i < foods.length; i++){
                
                let items=foods[i].getElementsByClassName("optionItem")
                for (let y = 0; y < items.length; y++){
                    let title=items[y].getAttribute("data-title");
                    let price=items[y].getAttribute("data-price");
                 if(json[i]){
                    let jsonObj=(json[i].items).find(x=>x.title==title)
                    if(jsonObj){
                        items[y].classList.add("selected")
                        let color="";
                        if(jsonObj.type=="add"){
                          color="text-success"
                        }
                        if(jsonObj.type=="subtract"){
                            color="text-danger"
                          }
                        if(jsonObj.type=="selective"){
                            color="text-black"
                        }
                        items[y].classList.remove("text-black","fa-circle")
                        items[y].classList.add(color,"fa-dot-circle")
                    }
                }
                }
            }
        }, 1000);
      
    }else{
        setOptionsModal(true)
    }
}
    return (
        <Fragment>

     
        <MDBCol lg={props.size ? props.size : '6'} className='py-0 px-2 border-bottom position-relative'>
            <style>{"\
        #LinkPayment{\
            display:none !important;\
        }\
        "}</style>

            <div className='h-100 rtl'>

                <Row className='flex-row-reverse m-0'>
                    <div className='pr-0 py-1  pr-md-0 d-none d-md-block col-md-2 px-0'>
                        <span onClick={() => setIsOpen(true)} className='p-0 overflow-hidden rounded-left nav-link Ripple-parent'>
                            <img className='img-fluid imgFood' src={props.mainImageFileName} alt={props.Title} />
                        </span>
                    </div>
                    <div className="text-right px-0 pl-1 col-md-10 col-12 px-0">
                        <MDBRow className='m-0 flex-row-reverse'>
                            <MDBCol size='6' md="5" className='pr-1 pl-0'>
                                <div className="text-left">
                                    <p className='text-black font-weight-bold pt-md-2 pt-0 pb-0 d-block m-0' style={{ 'font-size': '0.8em' }}>
                                        <span className='FoodTitle eqpro'>{i18n.language == "tr"?props.title:(i18n.language == "en"?props.titleEN:props.titleAR)}</span>
                                   
                                    </p>
                                    <strong className='font-weight-bold tl-font text-black-50'>₺{props.price}</strong>
                                    <input type='hidden' value={jsonOptions} className='jsonOptions' />
                                    <input type='hidden' value={additionalPrice} className='additionalPrice' />
                                    {(props.foodOptions??"").length>0?
                                    <span onClick={openModalFoodOptions} className='bg-success c-pointer ml-1 rounded shadow text-white eqpro'>{t("Seçenekler")}</span>
                                :
                                null    
                                }
                                     {jsonOptions?
                                        JSON.parse(jsonOptions).map((item,i)=>
                                            <p className='small m-0 ltr' key={i}>#{item.id}-{item.items.map((option,y)=><span key={y}>{option.title+(option.price!=0?' '+option.price:'')}{item.items.length==(y+1)?'':','}</span>)}</p>
                                        )
                                        :
                                        null}
                                </div>
                            </MDBCol>
                            <MDBCol size='6' md="7" className='d-flex flex-row-reverse justify-content-start justify-content-md-between px-0'>
                                <div className="my-auto ltr text-left ml-1 ml-md-0">
                                    <InputNumber ref={numberInput} addToCart={addToCart} foodID={props.foodID} default={count} />
                                </div>
                                <div className="my-auto text-left font-weight-bold small tl-font  py-md-0 px-2 px-md-0 text-black-50" >
                                    <p className='d-flex ltr'><span className='d-none d-md-block'>T = </span> <span>{total}₺</span></p>
                                    <p className='ltr m-0'>{additionalPrice!=0?((additionalPrice>0?'+':'')+additionalPrice):''}</p>
                                </div>
                                <div onClick={() => addToCart({ id: props.foodID, count: -10 })} className='d-none d-md-block btn-floating bg-light btn-sm px-0 mx-0' >
                                    <i className='fa fa-times text-black'></i>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>

                </Row>
                <Row>
                    <Button onClick={() => addToCart({ id: props.foodID, count: -10 })} size='sm' color='red' className='position-absolute py-1 px-2 d-none' style={{ left: '0px', bottom: '-2px' }}>
                        <i className='fa fa-trash-alt mt-1 pl-1'></i>
                        Delete
                    </Button>
                </Row>

                <MDBCol className={props.renderNumberClass}>
                </MDBCol>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={props.mainImageFileName}
                    onCloseRequest={() => setIsOpen(false)}
                    imageTitle={<p onClick={() => setIsOpen(false)} className="closeLightbox c-pointer"><i className="far fa-times-circle fa-3x"></i></p>}
                />
            )}
        </MDBCol>

        <MDBModal id="FoodOptions" className="form-cascading modal-notify  white-text" isOpen={optionsModal} toggle={optionsModalToggle}>
                <MDBModalHeader className="rounded-zolfa-top-right bg-light rounded-zolfa-top-left " titleClass="w-100 font-weight-bold" toggle={optionsModalToggle}>
                    <p className='m-0 text-black text-center font-weight-bold small eqpro'>{i18n.language == "tr"?props.title:(i18n.language == "en"?props.titleEN:props.titleAR)} {t("Seçenekler")}</p>
                </MDBModalHeader>
                <MDBModalBody>
            

{[...Array(count)].map((x, i) =>
   <Fragment key={i}>
    <button onClick={accordion} class="accordion-888">{i18n.language == "tr"?props.title:(i18n.language == "en"?props.titleEN:props.titleAR)} {i+1}</button>
<div class="panel text-left ltr">
<p className='mt-1 font-weight-bold eqpro'>{t("Ekle")}:</p>
{foodOptions.map((item, i) =>
    item.type=="add"?
                                          <div className='my-1 eqpro d-flex justify-content-between' key={i}><p><i onClick={(e)=>setOptionItem(e,item.type)} data-title={item.title} data-price={item.price} data-type={item.type} style={{ fontSize: '20px' }} className={`c-pointer checkbox-circle far fa-circle text-black mr-1 optionItem`}></i>{item.title}</p><p>{((item.price>0)?('+'+item.price+' ₺'):'')}</p></div>
                                          :
                                          null
                                        )}
                                        <hr className='my-1' />
                                     <p className='font-weight-bold eqpro'>{t("Çıkar")}:</p>
                                        {foodOptions.map((item, i) =>
    item.type=="subtract"?
                                          <div className='my-1 eqpro d-flex justify-content-between'  key={i}><p><i data-title={item.title} data-price={item.price} data-type={item.type} onClick={(e)=>setOptionItem(e,item.type)} style={{ fontSize: '20px' }}  className={`c-pointer checkbox-circle far fa-circle text-black mr-1 optionItem`}></i>{item.title}</p><p>{((item.price!=0)?('-'+item.price+' ₺'):'')}</p></div>
                                          :
                                          null
                                        )}
                                            {(props.selectiveOptions??"").split(",").map((selective,y)=>
                           <Fragment key={y}>   
                           <hr className='my-1' />
                                                                              <p className='font-weight-bold eqpro'>{selective}:</p>
                                        {foodOptions.map((item, i) =>
    (item.type=="selective" && item.id==(y+1).toString())?
                                          <div className='my-1 eqpro d-flex justify-content-between' key={i}><p><i data-title={item.title} data-price={item.price} data-type={item.type} data-id={item.id} onClick={(e)=>setOptionItem(e,item.type)} style={{ fontSize: '20px' }}  className={`c-pointer checkbox-circle far fa-circle text-black mr-1 optionItem`}></i>{item.title}</p><p>{((item.price>0)?('+'+item.price+' ₺'):'')}</p></div>
                                          :
                                          null
                                        )}
                           </Fragment>
                                            
                                            )}
</div>
   </Fragment>
  )}
                </MDBModalBody>
                <MDBModalFooter className="justify-content-center">
                    <span className="btn btn-success btn-rounded"  onClick={saveFoodOptions}>{t("Kaydet")}
                    </span>
                </MDBModalFooter>
            </MDBModal>
        </Fragment>
    )
}

export default Food
