import { MDBCardImage, MDBSpinner } from 'mdbreact'
import React, { Fragment } from 'react'
import { connect } from 'react-redux';

const ProfileCardHeader = (props) => {
    let { CurrentProfile } = props;
    return (
        <MDBCardImage
            className='view view-cascade bg-light py-3'
            cascade
            tag='div'>
            {CurrentProfile ?
                <Fragment>
                    <h6 className='text-black-50 font-weight-bold h6 ml-2 pt-1 mb-0'>{
                        props.title?
                            props.title
                            :
                            CurrentProfile.fullName
                    }</h6>
                </Fragment>
                :
               <i className='fa fa-2x fa-spin fa-spinner'></i>
            }
        </MDBCardImage>
    )
}

const mapStateToProps = state => {
    return {
        CurrentProfile: state.CurrentProfile,
    }
}


export default connect(mapStateToProps)(ProfileCardHeader) 
