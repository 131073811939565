import React, { useRef, useEffect, useState, Fragment } from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer';
import Loading from './Loading';
import { connect } from 'react-redux';
import { MDBCol, MDBContainer, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBTable, MDBTableBody, MDBTableHead, MDBInput, MDBIcon } from 'mdbreact';
import { ToastContainer } from 'react-toastify';
import { SetSearch } from './../actions/Website';
import configs from './../config';
import HomeMenu from './HomeMenu';
import { Link } from 'react-router-dom'
import Cart from './Food/Cart';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import axios from 'axios'
import LoginMenu from './Profile/LoginMenu';
import LoginRegisterModal from './Profile/LoginRegisterModal';

const Layout = (props) => {
    const [dark,setDark]=useState(false);
    const { Settings, SettingsLoading, FoodsLoading,IsSignedIn } = props;
    let [orderModal, setOrderModal] = useState(false)
    let [orderItems, setOrderItems] = useState([]);
    const [cookies, setCookie] = useCookies();
    const CartRef = useRef();
    const { t, i18n } = useTranslation();
    let [orders, setOrders] = useState([])
    const [search,setSearch]=useState(null)
    const handleSearch=()=>{
props.SetSearch(document.getElementById("Search").value)
    }
    const clearSearch=()=>{
        setSearch('')
        props.SetSearch('')
    }
useEffect(() => {
    var input = document.getElementById("Search");

    // Execute a function when the user presses a key on the keyboard
    input.addEventListener("keypress", function(event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        handleSearch()
      }
    });
}, [])

    const handleClick = (lang) => {
        i18n.changeLanguage(lang)
    }
    const changeToDark=()=>{
        setDark(!dark)
        if(dark==false){
            console.log("dark on")
            setCookie('dark', "on", { path: '/' });
        }else{
            console.log("dark off")
            setCookie('dark', "off", { path: '/' });
        }
    }
    useEffect(() => {
        if(cookies.dark){
            if(cookies.dark=="on"){
                setDark(true)
            }
        }

        if (cookies.Orders) {
            if (cookies.Orders.length > 0) {
                setOrders(cookies.Orders)
            }
        }
    }, [cookies.Orders])

    function outputsize() {
        let contentHeight = document.getElementsByTagName("body")[0].offsetHeight;
        contentHeight -= 300;
        if (document.getElementById("menu-placeholder")) {
            document.getElementById("menu-placeholder").style.height = contentHeight + "px";
        }

    }
    outputsize()

    new ResizeObserver(outputsize).observe(document.getElementsByTagName("body")[0])



    const closeOrderModal = () => {
        setOrderModal(false)
    }
    const openOrderModal = () => {
        setOrderModal(true)
        let bodyFormData = new FormData();
        bodyFormData.set('OrderIDs', cookies.Orders.toString());
        axios.post(configs.siteApiUrl + '/api/food/CustomerOrders', bodyFormData).then(res => {
            setOrderItems(res.data)
        })
    }
    return (
        <div id="site-content" className={`${cookies.dark=="on"?'darkmode bg-dark':'grey lighten-5'}`}>




            {orders.length > 0 ?
                <div className="orderHistory">
                    <div className="details-row py-1 font-weight-bold ltr">
                        <div className="align-self-center mx-1" style={{ direction: 'ltr' }}>
                            {/* <p>شما {orders.length} سفارش ثبت شده دارید</p> */}
                            <p className="ltr py-2">{orders.length} {t("Aktıf Siparişler")}</p></div>
                        <div className="align-self-center "><span onClick={openOrderModal} className="btn btn-green-c my-0 btn-rounded btn-sm mx-1">{t("göstermek")}</span>
                        </div>
                    </div>
                </div>
                :
                null
            }

            <MDBContainer fluid className="bg-light">
                <LoginRegisterModal />
             
                <MDBModal className="form-cascading modal-notify ltr mt-5" size="lg" id="orderModal" isOpen={orderModal}
                    toggle={closeOrderModal}>
                    <MDBModalHeader className="rounded-zolfa-top-right bg-light rounded-zolfa-top-left align-items-center" toggle={closeOrderModal}>
          <h5 className='text-black mb-0'>          {t("Aktıf Siparişler")}</h5>
                    </MDBModalHeader>
                    <MDBModalBody className='bg-white'>
                       
                        {orderItems.length > 0 ?
                            orderItems.map((item, i) =>
                        <MDBRow className='mb-3 rounded border py-2'>
                             <MDBCol size='12' className='d-flex flex-column text-black-50'>
                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                        <div>{t("Sipariş numarası")} : {item.orderCode}</div>
                                        <div>{t("Sipariş durumu")} : {item.status?t(item.status):t('Askıda olması')}</div>
                                </div>
                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                        <div>{t("Telefon Numarası")} : {item.phone}</div>
                                        <div>{t('Ad')+' & '+t("Soyad")} : {item.fullName+' '+item.lastName}</div>
                                </div>
                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                        <div>{t("Adres")} : {item.address}</div>
                                </div>
</MDBCol>
    <MDBCol key={i} className="my-2 rounded " size="12">
        <MDBTable responsive className='text-black'>
            <MDBTableHead>
                <tr>
                    <th>#</th>
                    <th>{t("Adet")}</th>
                    <th>{t("Fiyat")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {item.orderDetails.map((obj, numb) =>
                    <tr key={numb}>
                        <td className='pr-0'>{numb + 1}-{obj.title}</td>
                        <td>{obj.count}</td>
                        <td className="tl-font">{obj.total} ₺</td>
                    </tr>
                )}
                <tr>
                    <td rowSpan="2"><h5 className="font-weight-bold text-black">{t("Toplam")}:</h5></td>
                    <td></td>
                    <td rowSpan="2"><h5 className="font-weight-bold tl-font text-black">{item.total} ₺</h5></td>
                </tr>
            </MDBTableBody>
        </MDBTable>
    </MDBCol>
                        </MDBRow>
                            )
                            :
                            <MDBCol size="12" className="text-center"> <Loading /></MDBCol>
                        }
                    </MDBModalBody>

                </MDBModal>
                <ToastContainer />
                <MDBContainer>
                    <MDBRow id="logoAndLang" className="justify-content-end text-center rtl">
                        <MDBCol size="4" md="5" className="mt-2 pt-2 pt-md-3 text-right ">
                            <MDBRow className='h-100'>
                                    <div className='custom-control custom-switch position-absolute'>
                                        <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            id='customSwitches'
                                            checked={dark}
                                            onChange={()=>changeToDark()}
                                            readOnly
                                        />
                                        <label className='custom-control-label' htmlFor='customSwitches'>
                                            <i className={`fa fa-moon ${cookies.dark=="on"?"text-white":""}`}></i>
                                        </label>
                                    </div>
                                  
                                <MDBCol size='12' md="5" className='align-self-center px-0 px-md-2'>

                                    <Cart></Cart>
                         
                                </MDBCol>
                                <MDBCol size='7' className='align-self-center d-none d-md-flex'>
                                    <div className="input-group ltr">
                                        <i className={`fa fa-times position-absolute c-pointer searchClear ${search?'':'d-none'}`} onClick={clearSearch}></i>
                                        <input  type="email" className="form-control bg-light" value={search} onChange={(e)=>setSearch(e.target.value)} id="Search" aria-describedby="emailHelp" placeholder={t("Ara")}></input>
                                        <div onClick={handleSearch} className="input-group-prepend c-pointer">
                                            <div className="input-group-text bg-light"><i className='fa fa-search text-black-50'></i></div>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol id="divlogo" onClick={() => window.location.href = "/"} size="4" md="2" className="mt-2 c-pointer p-1 p-md-3">
                            <img src={Settings?Settings.logoFileName:''} className="img-fluid" alt='Zolfa food' />
                        {SettingsLoading ?
                                        <i className='fa fa-spin fa-spinner text-warning'></i>
                                        :
                                        Settings ?
                                           <h5 className='text-center font-weight-bolder mb-0 mt-2'>{Settings.title}</h5> : null
                                    }

                        </MDBCol>
                        <MDBCol size="4" md="5" className="mt-2 px-0 text-left pt-2 pt-md-3">

                            <div className=' float-left px-0 flex-center flex-column d-flex' style={{ 'z-index': '9999' }}>
                          <LoginMenu />
                        <div className='d-flex flex-row mb-md-3 mt-3 mt-md-0'>
                        {i18n.language != "en" ?
                                        <img onClick={() => { handleClick("en") }} src='./static/images/en.png' className='mx-1 mt-3 c-pointer langFlag ' alt='English' />
                                        : null}
                                    {i18n.language != "tr" ?
                                        <img onClick={() => { handleClick("tr") }} src='./static/images/tr.png' className='mx-1 mt-3 c-pointer langFlag ' alt='Türk' />
                                        : null
                                    }
                                    {i18n.language != "ar" ?
                                        <img onClick={() => { handleClick("ar") }} src='./static/images/ar.png' className='mx-1 mt-3 c-pointer langFlag ' alt='Arabic' />
                                        : null}
                        </div>
                                <div className='px-0 ltr font-weight-bold d-none d-md-flex' style={{ 'font-size': '0.8em' }}>
                                    {SettingsLoading ?
                                        <i className='fa fa-spin fa-spinner text-warning'></i>
                                        :
                                        Settings ?
                                            <a target='_blank' href={`tel:${Settings.mobile}`} style={{ fontSize: '1.1em' }} className='text-black-50'>
                                                <i className='fa fa-phone mr-1'></i>
                                                {Settings.mobile}
                                            </a> : null
                                    }

                                </div>
                                <div className='ltr text-black-50 mt-2 d-none d-md-flex' style={{ 'font-size': '0.8em' }}>
                                    <i className='fa fa-map mr-1'></i>
                                    <a target='_blank' href={Settings?Settings.footer:''} className="">  <p className="small text-black-50 eqpro">{Settings ? Settings.address : ''}</p></a>
                                </div>
                            </div>
                        </MDBCol>

                    </MDBRow>
                </MDBContainer>
                <div style={{ left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }} id="menu-placeholder" className="py-4 position-absolute col-12 col-md-6 d-none d-md-block">
                    <HomeMenu></HomeMenu>
                </div>
         
            </MDBContainer>
            <NavMenu CartRef={props.CartRef} />
            {props.children}

            <Footer />
        </div>
    )
}
const mapStateToProps = (state, props) => {
    return {
        Settings: state.Settings.Data,
        SettingsLoading: state.Settings.Loading,
        FoodsLoading: state.Foods.Loading,
        Search:state.Search,
        IsSignedIn: state.IsSignedIn.Value
    }
}
const mapDispatchToProps = dispatch => {
    return {
        SetSearch: (search) => dispatch(SetSearch(search)),
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Layout)
