import React, { Fragment, useState, useEffect } from 'react';
import {
  MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBNav, MDBNavItem, MDBNavLink, MDBInput, MDBModalFooter,
  MDBIcon, MDBTabContent, MDBTabPane, MDBRow, MDBModalHeader, MDBCol
} from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import AjaxForm from './../AjaxForm';
import configs from './../../config';
import { GetCurrentProfile } from './../../actions/profile'

const MyAddress = (props) => {
  const [cookies, setCookie] = useCookies();
  const emptyValues={ address: "", daire: "", kat: "", blok: "", firstName: "", lastName: "",profileAddressID:"" };
  let [values, setValues] = useState(emptyValues)
  let [errors, setErrors] = useState({});
  let [selectedAddress, setSelectedAddress] = useState("")
  let { CurrentProfile } = props;
  const { t, i18n } = useTranslation();
  let [modal, setModal] = useState(false);
  let [addModal, setAddModal] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }
  const beforeSubmit = () => {
    const ValidateErrors = handleValidate();
    setErrors(ValidateErrors)
    if (Object.keys(ValidateErrors).length > 0) {
      return false;
    }
  }
  const handleValidate = () => {
    let errors = {};
    if (!values.address) {
      errors.address = t("Adres gerekli");
    }
    if (!values.firstName) {
      errors.firstName = t("İsim gerekli");
    }
    if (!values.lastName) {
      errors.lastName = t("soyadı gerekli");
    }
    if (values.address) {
      if (values.address.length < 3) {
        errors.address = t("adres doğru değil");
      }
    }
    return errors;
  }

  useEffect(() => {
    if (CurrentProfile) {
      if (CurrentProfile.profileAddresses) {
        if (CurrentProfile.profileAddresses.length > 0) {
          let address = CurrentProfile.profileAddresses.find(x => x.default);
          if (address) {
            setSelectedAddress(address.profileAddressID)
          }
        }
      }
    }
  }, [CurrentProfile])


  const DoneAdd = (data) => {
    if(document.getElementById("cartAddress")){
      document.getElementById("cartAddress").innerText = values.address;
      document.getElementById("cartDaire").innerText = values.daire;
      document.getElementById("cartKat").innerText = values.kat;
      document.getElementById("cartBlok").innerText = values.blok;
    }
    closeAdd()
    props.GetCurrentProfile(cookies.AccessToken)
  }
  const toggle = () => {
    setModal(!modal);
  }
  const toggleAdd = () => {
    setAddModal(!addModal);
  }
  const close = () => {
    setModal(false);
  }
  const closeAdd = () => {
    setAddModal(false);
  }
  const Fail = () => {

  }
  const selectAddress = (item) => {
    setSelectedAddress(item.profileAddressID)
    if(document.getElementById("cartAddress")){
      document.getElementById("cartAddress").innerText = item.address;
      document.getElementById("cartDaire").innerText = item.daire;
      document.getElementById("cartKat").innerText = item.kat;
      document.getElementById("cartBlok").innerText = item.blok;
    }
  }

  const editAddress=(item)=>{
    setValues({ ...item })
    setAddModal(true);
  }
  return (
    <Fragment>
      <span id="btnChangeAddressModal" className="d-none" rounded onClick={() => {
        toggle()
      }}></span>
      <span id="btnAddAddressModal" className="d-none" rounded onClick={() => {
        toggleAdd()
      }}></span>

      <div className='rounded-zolfa-bottom-right rounded-zolfa-bottom-left p-0 bg-white ltr text-left'>
        {/* <h5 className='text-black text-center m-0'> <i className={`pr-1 fa fa-user`}></i>{t("Adres Seçin")}</h5> */}
        {CurrentProfile ?
          CurrentProfile.profileAddresses ?
            CurrentProfile.profileAddresses.map((item, i) =>
              <div key={i} onClick={() => selectAddress(item)} className={`divAddressItem row bg-light p-2 m-2 c-pointer`}>
                <MDBCol className='pl-0 pr-1' size='9' md='10'>
                  <p className='text-black font-weight-bold'>{item.firstName} {item.lastName}</p>
                  <p>{item.address} , <span>Blok : {item.blok}</span> , <span>Kat : {item.kat}</span> , <span>Daire : {item.daire}</span></p>
                </MDBCol>
                <MDBCol className='text-center d-flex flex-center align-self-center border-left-888 p-0' size='3' md='2'>
                  {props.shoppingCart ?
                    <i className={`checkbox-circle ${item.profileAddressID == selectedAddress ? 'fa fa-circle orange-text' : 'far fa-circle text-black'}`}></i>
                    :
                    <span onClick={()=>editAddress(item)} className='btn btn-floating btn-sm btn-success m-0 p-0'><i className='fa fa-pen'></i></span>
                  }
                </MDBCol>
              </div>
            )
            :
            null
          :
          <p className='m-0'><i className='fa fa-spin fa-spinner fa-2x text-warning'></i></p>
        }
        <p onClick={() => {
          toggleAdd()
         setValues(emptyValues)
        }} className='ltr text-right c-pointer p-2 text-info m-0'>+ {t("Adres Ekle")}</p>
      </div>


      <MDBModal id="AddAddressModal" className="form-cascading modal-notify ltr text-left" isOpen={addModal} toggle={toggleAdd}>
        <MDBModalHeader className='rounded-zolfa-top-right bg-light rounded-zolfa-top-left' toggle={toggleAdd}>
          <MDBRow>
            <MDBCol className='text-center'>

              <h5 className='text-black m-0'> <i className={`pr-1 fa fa-user`}></i>{t("Adres Ekle")}</h5>
            </MDBCol>
          </MDBRow>

        </MDBModalHeader>
        <MDBModalBody className='rounded-zolfa-bottom-right rounded-zolfa-bottom-left p-0 bg-white'>
          <AjaxForm className="m-0 p-2 mt-2 ltr" api={configs.siteApiUrl + '/api/user/AddProfileAddress'} onFail={Fail} onSuccess={DoneAdd} beforeSubmit={beforeSubmit}>
            {CurrentProfile ?
              <input type='hidden' name='ProfileID' value={CurrentProfile.profileID} />
              :
              null
            }
            <MDBCol className='pl-0 pr-1' size='6'>
            <input type='hidden' name='profileAddressID' value={values.profileAddressID} />
              <span className="mr-z font-weight-bold text-black">{t("Ad")}:</span>
              <input onChange={handleChange} className="form-control bg-light  ltr placeholder" name="firstName" value={values.firstName} />
              {errors.firstName && <p className='text-danger'>{errors.firstName}</p>}
            </MDBCol>
            <MDBCol className='pr-0 pl-1' size='6'>
              <span className="mr-z font-weight-bold text-black">{t("Soyad")}:</span>
              <input onChange={handleChange} className="form-control bg-light  ltr placeholder" name="lastName" value={values.lastName} />
              {errors.lastName && <p className='text-danger'>{errors.lastName}</p>}
            </MDBCol>

            <span className="mr-z font-weight-bold text-black mt-2">{t("Adres")}:</span>
            <textarea onChange={handleChange} className="form-control  bg-light ltr placeholder" name="address" style={{ resize: 'none' }} value={values.address} rows="2"></textarea>
            {errors.address && <p className='text-danger'>{errors.address}</p>}
            <div className='d-flex flex-row justify-content-between mt-3 flex-center'>
              <span className="mr-z font-weight-bold text-black">{t("Daire")}: </span>
              <input onChange={handleChange} className="form-control bg-light  ltr placeholder mr-1" name="daire" value={values.daire} />
              <span className="mr-z font-weight-bold text-black ml-1">{t("Kat")}: </span>
              <input onChange={handleChange} className="form-control bg-light  ltr placeholder mr-1" name="kat" value={values.kat} />
              <span className="mr-z font-weight-bold text-black ml-1">{t("Blok")}: </span>
              <input onChange={handleChange} className="form-control bg-light  ltr placeholder" name="blok" value={values.blok} />
            </div>
            <MDBCol size='12' className={`text-right rounded-zolfa-bottom-right rounded-zolfa-bottom-left py-3 px-0`}>
              <button className="btn btn-success btn-rounded  waves-effect z-depth-0 mr-0" type="submit">{t("Kaydet")}</button>
            </MDBCol>

          </AjaxForm>
        </MDBModalBody>

        <p onClick={closeAdd} className="d-none" id="closeLoginModal">Close</p>
      </MDBModal>
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  return {
    IsSignedIn: state.IsSignedIn.Value,
    CurrentProfile: state.CurrentProfile
  }
}
const mapDispatchToProps = dispatch => {
  return {
    GetCurrentProfile: (token) => dispatch(GetCurrentProfile(token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAddress)