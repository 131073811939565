import * as ActionTypes from './../../actions/ActionTypes'
const initialSignedIn = {
    Loading: true, Value: false
}
const initialRoles = {
    Loading: true, Value: null
}
export const CurrentProfile = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Profile_CurrentUser:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}

export const IsSignedIn = (state = initialSignedIn, action) => {
    switch (action.type) {
        case ActionTypes.Profile_IsSignedIn:
            return {
                Value: action.payload,
                Loading: false
            }
        default:
            return state;
    }
}

export const CurrentProfileRoles = (state = initialRoles, action) => {
    switch (action.type) {
        case ActionTypes.Profile_CurrentUser_Roles:
            return {
                Value: action.payload,
                Loading: false
            }
        default:
            return state;
    }
}

export const AccessToken = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.Profile_AccessToken:
            return action.value
        default:
            return state;
    }
}