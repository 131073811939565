import axios from 'axios';
import * as ActionTypes from './../ActionTypes';
import configs from '../../config';
export const GetFoodList = () => async dispatch => {
    let bodyFormData = new FormData();
    bodyFormData.set('SubscriptionID', configs.subscriptionID);
    const result = await axios.post(configs.siteApiUrl+'/api/food/foods',bodyFormData);
    dispatch({
        type: ActionTypes.Food_List,
        payload: result.data
    })
}

export const GetTypeList = () => async dispatch => {
    let bodyFormData = new FormData();
    bodyFormData.set('SubscriptionID', configs.subscriptionID);
    const result = await axios.post(configs.siteApiUrl+'/api/food/PublicTypes',bodyFormData);
    dispatch({
        type: ActionTypes.FoodType_List,
        payload: result.data
    })
}

export const UpdateCart = (id, count) => {
    return {
        type: ActionTypes.Cart,
        payload: { id, count }
    }
}