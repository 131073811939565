import React, { Component, Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MDBRow, MDBCol, MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBBtn, MDBModalFooter } from 'mdbreact';
import FoodMenu from './Food/FoodMenu';
import HomeMenu from './HomeMenu';
import Loading from './Loading'
import { SetSearch } from './../actions/Website';
import $ from 'jquery'
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import ModalMessage from './ModalMessage';
import axios from 'axios';
import configs from './../config';
const Home = (props) => {
    const moment = require('moment');
    let [stepRequest,setStepRequest]=useState(1)
    let [requestModal, setRequestModal] = useState(false)
    let queryParamParse = queryString.parse(window.location.search);
    const queryParam = Object.keys(queryParamParse).reduce((c, k) => (c[k.toLowerCase()] = queryParamParse[k], c), {});
    useEffect(() => {
        if(queryParam.table){
            console.log('qr scanned')
            console.log(queryParam.table)
            setRequestModal(true)
        }else{
            console.log('no qr')
        }
    }, [])
    
    const modalMessageRef = useRef();
    let { FoodsLoading, SettingsLoading, Settings,TypesLoading ,Types} = props;
    let [closedModal, setClosedModal] = useState(false)
    let [cartItems, setCartItems] = useState([])
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies();
    const [foodState,setFoodState]=useState([])
    const [search,setSearch]=useState(null)
    const handleSearch=()=>{
        props.SetSearch(document.getElementById("SearchMobile").value)
            }
            const clearSearch=()=>{
                setSearch('')
                props.SetSearch('')
            }
    

    useEffect(() => {
        if (cookies.CartItems) {
            if (cookies.CartItems.length > 0) {
                let jsonCartItems = cookies.CartItems;
                jsonCartItems = jsonCartItems.filter(function (x) { return x.count !== 0 })
                setCartItems(jsonCartItems)
            
            } else {
                setCartItems([])
               
            }
        }
    }, [cookies.CartItems])



    useEffect(() => {

        let location = window.location.href.split('#')[1];
        if (location) {
            let elm = document.getElementById(location)
            if (elm) {
                elm.scrollIntoView({ behavior: 'smooth' })
                setTimeout(() => {
                    trackScrolling()
                }, 50);
            }
        }
        return () => {
        }
    }, [FoodsLoading]);
    useEffect(() => {

        if (Settings) {
            if (!Settings.restaurantStatus) {
                setClosedModal(true)
            }
        }
    }, [SettingsLoading])
    function elementInViewport2(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    const trackScrolling = () => {
        let el = document.getElementById('divHomeMenu');
        if (elementInViewport2(el)) {
            document.getElementById('HomeMenuDesktop').classList.remove('d-md-flex');
            document.getElementById('HomeMenuImage').classList.add('d-none');
            document.getElementById('HomeMenuNav').classList.add('noHomeMenu');
        } else {
            document.getElementById('HomeMenuDesktop').classList.add('d-md-flex');
            document.getElementById('HomeMenuImage').classList.remove('d-none');
            document.getElementById('HomeMenuNav').classList.remove('noHomeMenu');
        }
    }
    const closedModalToggle = () => {
        setClosedModal(!closedModal)
    }
    const requestModalToggle = () => {
        setRequestModal(!requestModal)
    }

    const request=(message)=>{
        let bodyFormData = new FormData();
        bodyFormData.set('Message', message);
        bodyFormData.set('SubscriptionID', configs.subscriptionID);
        bodyFormData.set('Data1', queryParam.table);
        axios.post(configs.siteApiUrl + '/api/Notification/CustomerRequest', bodyFormData).then(res => {
            setStepRequest(4)
        });
    }


    return (
        <MDBContainer className="">
              <ModalMessage ref={modalMessageRef} />
              <MDBModal id="requestModal" className="form-cascading modal-notify ltr rounded-zolfa" isOpen={requestModal} toggle={requestModalToggle}>
                <MDBModalHeader className="rounded-zolfa-top-right bg-light rounded-zolfa-top-left" titleClass="w-100" toggle={requestModalToggle}>
                    <p style={{fontSize: '15px'}} className='text-center'><i className='fa fa-calendar-alt pr-1'></i>{moment.utc().local().format("MMM D, YYYY HH:mm")}</p>
                </MDBModalHeader>
                <MDBModalBody className='bg-white rounded-zolfa-bottom-right rounded-zolfa-bottom-left'>
                    <div className='row justify-content-center'>
                        {stepRequest==1?
                        <Fragment>
                                <MDBCol size='12' md='6' className='text-center d-flex flex-column justify-content-center flex-center'>
                            {Settings ?
                            <img src={Settings.logoFileName} className='img-fluid' />
                            :
                            null
                        }
                        <span onClick={requestModalToggle} className='btn-orange btn btn-block btn-rounded my-3'>Show Menu</span>
                            </MDBCol>
                            <MDBCol size='12' className='text-center my-1 text-black-50 font-weight-bold'>
                                <h5 className='font-weight-bold'>Table {queryParam.table}</h5>
                            </MDBCol>
                            <MDBCol size='12' className='d-flex justify-content-between text-black-50'>
                                <span onClick={()=>request("Garson")} className='btnRequest'><i className='fa fa-user pb-2'></i><span className='small'>Garson</span></span>
                                <span onClick={()=>setStepRequest(2)} className='btnRequest'><i className='fa fa-phone pb-2'></i><span className='small'>Call Service</span></span>
                                <span onClick={()=>setStepRequest(3)} className='btnRequest'><i className='fa fa-list-alt pb-2'></i><span className='small'>Request Check</span></span>
                            </MDBCol>
                        </Fragment>
                    :
                    null
                    }
                        {stepRequest==2?
       <Fragment>
   <MDBCol size='12' className='text-center my-1 text-black-50 font-weight-bold'>
       <h5 className='font-weight-bold'>Table {queryParam.table}</h5>
   </MDBCol>
   <MDBCol size='12' className='d-flex flex-column flex-center justify-content-center'>
<h6>Select What You Want:</h6>
<span onClick={()=>request("Tissue")} className='btn btn-orange btn-rounded'><span className='small'>Tissue</span></span>
<span onClick={()=>request("Glass")} className='btn btn-orange btn-rounded'><span className='small'>Glass</span></span>
<span onClick={()=>request("Plate")} className='btn btn-orange btn-rounded'><span className='small'>Plate</span></span>
<span onClick={()=>request("Sauce")} className='btn btn-orange btn-rounded'><span className='small'>Sauce</span></span>
<span onClick={()=>request("Bread")} className='btn btn-orange btn-rounded'><span className='small'>Bread</span></span>
   </MDBCol>
   <MDBCol size='12' className='text-center'>
   <span onClick={()=>setStepRequest(1)} className='btn btn-outline-blue-grey btn-sm btn-rounded'><i className='fa fa-arrow-left pr-1'></i><span className=''>Back To Home</span></span>
   </MDBCol>
</Fragment>
                        :
                        null
}
{stepRequest==3?
       <Fragment>
           <MDBCol size='12' className='text-center my-1 text-black-50 font-weight-bold'>
       <h5 className='font-weight-bold'>Table {queryParam.table}</h5>
   </MDBCol>
       <MDBCol size='12' md='6' className='text-center d-flex flex-column justify-content-center flex-center'>
   {Settings ?
   <img src={Settings.logoFileName} className='img-fluid' />
   :
   null
}
   </MDBCol>
   <MDBCol size='12' className='d-flex justify-content-between text-black-50'>
       <span onClick={()=>request("Check (Cash)")} className='btn btn-orange btn-rounded m-0'><i className='fa fa-money-bill-wave-alt pr-1'></i><span className='small'>Cash</span></span>
       <span onClick={()=>request("Check (Card)")} className='btn btn-orange btn-rounded m-0'><i className='fa fa-credit-card pr-1'></i><span className='small'>Credit Card</span></span>
   </MDBCol>
   <MDBCol size='12' className='text-center'>
   <span onClick={()=>setStepRequest(1)} className='btn btn-outline-blue-grey btn-sm btn-rounded'><i className='fa fa-arrow-left pr-1'></i><span className=''>Back To Home</span></span>
   </MDBCol>
</Fragment>
                        :
                        null
}
{stepRequest==4?
                        <Fragment>
<MDBCol size='12' className='text-center d-flex flex-center flex-column'>
<h5>Your Request Has Been Received</h5>
<i className='fa fa-2x fa-check'></i>
</MDBCol>
<MDBCol size='12' className='text-center'>
   <span onClick={()=>setStepRequest(1)} className='btn btn-outline-blue-grey btn-sm btn-rounded'><i className='fa fa-arrow-left pr-1'></i><span className=''>Back To Home</span></span>
   </MDBCol>
                        </Fragment>
                        :
                        null
}
                    </div>
                
                </MDBModalBody>
             
            </MDBModal>
            <MDBModal id="closedModal" className="form-cascading modal-notify  white-text" isOpen={closedModal} toggle={closedModalToggle}>
                <MDBModalHeader className="rounded-zolfa-top-right bg-light rounded-zolfa-top-left " titleClass="w-100 font-weight-bold" toggle={closedModalToggle}></MDBModalHeader>
                <MDBModalBody>
                    <div className="rtl text-center">
                        {Settings ?
                            Settings.closedCaption
                            :
                            null
                        }
                    </div>
                </MDBModalBody>
                <MDBModalFooter className="justify-content-center">
                    <span className="btn btn-success btn-rounded"  onClick={closedModalToggle}>close
                    </span>
                </MDBModalFooter>
            </MDBModal>
            <MDBRow className="mt-3">
                <MDBCol size='12' className='d-md-none'>
                <div className="input-group ltr">
                <i className={`fa fa-times position-absolute c-pointer searchClear ${search?'':'d-none'}`} onClick={clearSearch}></i>
                                        <input value={search} onChange={(e)=>setSearch(e.target.value)} type="email" className="form-control bg-light" id="SearchMobile" aria-describedby="emailHelp" placeholder="Search"></input>
                                        <div onClick={handleSearch} className="input-group-prepend c-pointer">
                                            <div className="input-group-text bg-light"><i className='fa fa-search text-black-50'></i></div>
                                        </div>
                                    </div>
                </MDBCol>
                {FoodsLoading && TypesLoading?
                    <MDBCol size="12" className="text-center mt-5 mh-100"><div className="mt-4"> <Loading /></div></MDBCol> :
                    <Fragment>
                           {Types.map((item, i) =>
                        <FoodMenu id={item.title} Type={item.title} Title={item.title} Description=' '></FoodMenu>
            )}
                 
                        {/* <FoodMenu id="atıştırmak" Type='Atıştırmak' Title={t("Atıştırmak")} Description='توضیحات منو'></FoodMenu>
                        <FoodMenu id="burger" Type='BURGER' Title={t("Burger")} Description='توضیحات منو'></FoodMenu>
                        <FoodMenu id="international" Type='فرنگی' Title={t("Dünya Mutfağı")} Description='توضیحات منو'></FoodMenu> */}
                        {/* <FoodMenu id="dessert" Type='دسر' TitleFa="دسر" TitleEn="Dessert" Description='توضیحات منو'></FoodMenu> */}
                        {/* <FoodMenu id="tavuk" Type='TAVUK ve HOT DOG' Title={t("TAVUK ve HOT DOG")}  Description='توضیحات منو'></FoodMenu>
                        <FoodMenu id="vegan" Type='Vegan' Title={t("Vegan")} Description='توضیحات منو'></FoodMenu>
                        <FoodMenu id="icicekler" Type='Icicekler' Title={t("İçecek")}  Description='توضیحات منو'></FoodMenu> */}
                    </Fragment>
                }

            </MDBRow>
            {((queryParam.table) && (!requestModal))?
            <div onClick={()=>setRequestModal(true)} className='requestFix'>
            <p className='m-0 py-2 small text-center w-100'>Services <i className="fa fa-bell" style={{ fontSize: '1.2em' }}></i></p>
            </div>
            :null
            }

        </MDBContainer>
    )
}

const mapStateToProps = state => {
    return {
        FoodsLoading: state.Foods.Loading,
        Types: state.Types.Data,
        TypesLoading: state.Types.Loading,
        IsSignedInLoading: state.IsSignedIn.Loading,
        IsSignedIn: state.IsSignedIn.Value,
        CurrentProfile: state.CurrentProfile,
        CurrentProfileRoles: state.CurrentProfileRoles.Value,
        CurrentProfileRolesLoading: state.CurrentProfileRoles.Loading,
        Settings: state.Settings.Data,
        SettingsLoading: state.Settings.Loading,
        Search:state.Search
    }
}
const mapDispatchToProps = dispatch => {
    return {
        SetSearch: (search) => dispatch(SetSearch(search)),
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Home)