
export const Profile_CurrentUser = 'Profile_CurrentUser';
export const Profile_CurrentUser_Roles = 'Profile_CurrentUser_Roles';
export const Profile_IsSignedIn = 'Profile_IsSignedIn';
export const Profile_AccessToken = 'Profile_AccessToken';
export const Comment_List='Comment_List';
export const Food_List='Food_List';
export const FoodType_List='FoodType_List';
export const Cart='Cart';
export const Search='Search';
export const Setting_List='Setting_List';

