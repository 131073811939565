import React from 'react';


const Loading = (props) => {
     return (

         <i className={'fa fa-spin fa-spinner ' + (props.colorClass !== undefined ? (props.colorClass) : ('text-warning')) +' ' + (props.size !== undefined ? (props.size) : ('fa-4x'))}></i>

        )
}

export default Loading;