import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef, Fragment } from 'react'
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBModalFooter, MDBRow, MDBCol } from 'mdbreact'
import ShoppingCart from './ShoppingCart';

const Cart = (props) => {
    const { t, i18n } = useTranslation();
    let [cartModal, setCartModal] = useState(false)
    const [cookies, setCookie] = useCookies();
    let { Settings } = props
    const closeCartModal = () => {
        setCartModal(false)
    }
    const openCartModal = () => {
        setCartModal(true)
    }
    useEffect(() => {
        let jsonCartItems = cookies.CartItems ? cookies.CartItems : JSON.parse('[]');
        jsonCartItems = jsonCartItems.filter(function (x) { return x.count !== 0 })
        setCookie('CartItems', JSON.stringify(jsonCartItems), { path: '/' });
        document.getElementById('spnShoppingCartCount').innerText = jsonCartItems.reduce((sum, x) => sum + x.count, 0);
        document.getElementById('spnShoppingCartPrice').innerText = parseFloat(jsonCartItems.reduce((sum, x) => sum + x.count * x.price, 0)).toFixed(0)+'₺';
    }, [])

    return (
        <Fragment>
            <MDBModal className="form-cascading modal-notify ltr" size="lg" id="cartModal" isOpen={cartModal}
                toggle={closeCartModal}>
                <ShoppingCart closeCartModal={closeCartModal}></ShoppingCart>
            </MDBModal>
            <div id='divCapsule' className='text-right w-auto position-fixed' onClick={openCartModal}>
                <div id='btnShoppingCart' className="btn btn-green-c px-3 btn-rounded posiiton-relative p-0 overflow-hidden">
                    
                    <span className='h-100 px-0 py-2 overflow-hidden'>
                    <i className='fa fa-shopping-basket black-text pl-1' style={{ 'font-size': '1.2em' }}></i>
                        <span id='spnShoppingCartPrice' className=' ltr d-inline-block black-text py-2 tl-font'>0</span>
                        </span>
                    <span id='spnShoppingCartCount' class="btn-floating btn-sm btn-danger position-absolute" style={{ bottom: '18px', right: '-5px', 'font-size': '1em', width: 24, height: 24, 'line-height': '2.1em' }}>0</span>
                </div>
            </div>
            {cookies.CartItems ?
                cookies.CartItems.length > 0 ?
                    <div className="cartFix c-pointer d-none" onClick={openCartModal}>
                        <span className="cartFixCount bg-danger flex-center position-absolute text-center text-white">{cookies.CartItems?cookies.CartItems.reduce((sum, x) => sum + x.count, 0):0}</span>
                        <div style={{ flex: '1' }} className=" my-auto">
                            {/* <p style={{ borderRadius: '19px 0px 0px' }} className=" ltr  white black-text tl-font m-0 w-100 small text-center">₺ {parseFloat(cartItems.reduce((sum, x) => sum + x.count * x.price, 0)).toFixed(0)}</p> */}
                            <p className="ltr  small m-0 text-center py-2 eqpro">{t("Siparişi Onayla")} <i className="fa fa-shopping-basket" style={{ fontSize: '1.2em' }}></i></p>
                        </div>
                    </div>


                    :
                    null
                :
                null
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        Settings: state.Settings.Data,
        SettingsLoading: state.Settings.Loading
    }
}

export default connect(mapStateToProps)(Cart)
