import numeral from 'numeral';
const helpers = {
    getCookie: function (cookieName) {
        var name = cookieName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    },
    numeralFormat:function(number){
        let format ='0,0[.][00000]';   
        return  numeral(number).format(format)
    },
    AddDays: function (date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

}
export default helpers;