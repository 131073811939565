import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import {
    MDBNavItem, MDBNavLink, MDBIcon
} from "mdbreact";
const LoginMenu = (props) => {
    const { t, i18n } = useTranslation();
    let { IsSignedIn, IsSignedInLoading, CurrentProfile, CurrentProfileRoles, CurrentProfileRolesLoading } = props;
    return (
        <Fragment>
            {IsSignedInLoading?<span><i className='fa fa-spin fa-spinner text-warning'></i></span>:
                        IsSignedIn ?
                        <Fragment>
                            {/* <MDBNavItem>
                                <MDBNavLink tag={Link} to="/Logout"> <MDBIcon icon="sign-out-alt" className="mr-1" />  {t("خروج")}</MDBNavLink>
                             
                            </MDBNavItem>  */}
                             <Link to="/Profile">
                              <div title={t("Profil")} className=' divProfileIcon d-md-flex d-none'>
                                            <span className='btn btn-floating btn-orange'><i className='fa fa-user'></i></span>
                                    </div>
                                    </Link>
                              
</Fragment>
                            :
                            <Fragment>
                                <div onClick={ ()=>document.getElementById("openLoginModal").click()} title={t("Giriş yap/Kayıt ol")} className=' divProfileIcon d-md-flex d-none'>
                                            <span className='btn btn-floating btn-orange'><i className='fa fa-user'></i></span>
                                    </div>

                            </Fragment>
                        }
        </Fragment>
    )
}


const mapStateToProps = state => {
    return {
        IsSignedIn: state.IsSignedIn.Value,
        IsSignedInLoading: state.IsSignedIn.Loading,
        CurrentProfile: state.CurrentProfile,
        CurrentProfileRoles: state.CurrentProfileRoles.Value,
        CurrentProfileRolesLoading: state.CurrentProfileRoles.Loading,
    }
}

export default connect(mapStateToProps)(LoginMenu)

