import { MDBCol } from 'mdbreact';
import React, { Fragment,useEffect } from 'react'
import Food from './Food';
import { connect } from 'react-redux';
import $ from 'jquery'
const FoodMenu = (props) => {
    useEffect(() => {
        $('p.lineContainer').each(function(){
            var textWidth = 0;
            var container = $(this);
            var parentWidth = container.parent().innerWidth();
            var lineCount = container.find('.line').length;
            
            container.find('.text').each(function(){
                textWidth += $(this).outerWidth()+20;
            });
            var lineWidth = (parentWidth-textWidth)/lineCount;
        
            container.find('.line').css('width',lineWidth+'px');
            
            $(window).on('resize',function() {
                var textWidth = 0;
                var parentWidth = container.parent().innerWidth();
                container.find('.text').each(function(){
                    textWidth += $(this).outerWidth()+20;
                });
                var lineWidth = (parentWidth-textWidth)/lineCount;
           
                container.find('.line').css('width',lineWidth+'px');
            });
        });
      return () => {
        
      }
    }, [])
    let { FoodsLoading, Foods } = props;
    return (
        <Fragment>
            {Foods.length > 0 ?
                <MDBCol id={props.id} size='12' className='mt-0 text-center pt-5 px-0'>

            <div className='w-100'>
            <p className="card-header-title lineContainer pt-md-4 pt-2 text-black position-relative font-weight-bold"> 
            <span class="line"></span><span class="text eqpro">{props.Title}</span><span class="line"></span>
                    </p>
            </div>
                </MDBCol> : null
            }
            {Foods.map((item, i) =>
                <Food renderNumberClass='d-none' key={i} {...item} />
            )}

        </Fragment>
    )
}

const mapStateToProps = (state, props) => {
    return {
        Foods: state.Foods.Data.filter(x => (x.type.includes(props.Type)) && ((x.title??"").toLowerCase().includes((state.Search??"").toLowerCase()))),
        FoodsLoading: state.Foods.Loading,
    }
}

export default connect(mapStateToProps)(FoodMenu)
