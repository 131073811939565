import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import configs from '../../config';
import { useCookies } from 'react-cookie';

const AjaxForm = (props) => {
    const [cookies, setCookie] = useCookies();
    let {beforeSubmit,api,onSuccess,onFail,AccessToken}=props;
     const handleSubmit=(e)=>{
        e.preventDefault();
        if(beforeSubmit){
            if (typeof beforeSubmit === 'function') {
               let beforeSubmitResult= beforeSubmit();
               if (beforeSubmitResult === false) {
                   return false;
               }
            }
        }
        let formdata=new FormData(e.target);
        formdata.append("SubscriptionID",configs.subscriptionID)
        axios.post(api, formdata,{
            headers: !cookies.AccessToken ? {} : { 'Authorization': `Bearer ${cookies.AccessToken}` }
          })
        .then(function (response) {
            if(response.data.success){
                onSuccess(response.data.data)
            }else{
                onFail(response.data.data)
            }
          });
    }
    return (
        <form className={`row ${props.className}`} onSubmit={handleSubmit}>
            {props.children}
        </form>
    )
}

const mapStateToProps = state => {
    return {
        AccessToken:state.AccessToken
    }
}
export default connect(mapStateToProps)(AjaxForm)

