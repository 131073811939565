import * as type from './../../actions/ActionTypes';
const initialState = {
    Data: null,
    Loading: true
}

export function Settings(state = initialState, action) {
    switch (action.type) {
        case type.Setting_List:
            return {
                ...state,
                Data: action.payload,
                Loading: false
            }      
        default:
            return state
    }
}

export const Search = (state = null, action) => {
    switch (action.type) {
        case type.Search:
            return action.value
        default:
            return state;
    }
}