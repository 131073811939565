import { MDBCol, MDBRow } from 'mdbreact'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

const HomeMenu = (props) => {
    let {Types,TypesLoading}=props
    const [cookies, setCookie] = useCookies();
    const { t, i18n } = useTranslation();
    const scrollIntoID = (id) => {
        let elm = document.getElementById(id)
        if (elm) {
            elm.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                trackScrolling()
            }, 50);
        } else {
            props.history.push("/#" + id)
        }
    }

    function elementInViewport2(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    const trackScrolling = () => {
        let el = document.getElementById('divHomeMenu');
        // if (elementInViewport2(el)) {
        //     // document.getElementById('HomeMenuDesktop').classList.remove('d-md-flex');
        //     document.getElementById('HomeMenuImage').classList.add('d-none');
        //     // document.getElementById('HomeSideNav').classList.add('d-none');
        //     // document.getElementById('HomeMenuNav').classList.add('noHomeMenu');
        // } else {
        //     // document.getElementById('HomeMenuDesktop').classList.add('d-md-flex');
        //     document.getElementById('HomeMenuImage').classList.remove('d-none');
        //     // document.getElementById('HomeSideNav').classList.remove('d-none');
        //     // document.getElementById('HomeMenuNav').classList.remove('noHomeMenu');
        // }
    }
   
    document.addEventListener('scroll', trackScrolling);

    return (
        <div id='divHomeMenu' style={{ top: '13px', position: 'sticky', zIndex: '999',border:'1px solid',borderColor:cookies.dark=="on"?"#fff":"#000" }} className='btn bg-white btn-block btn-rounded py-0 px-3 m-0'>
            <MDBRow className="position-relative">
                {!TypesLoading?
                           Types.map((item, i) =>
                            <MDBCol onClick={() => scrollIntoID(item.title)} style={{borderRight:(Types.length-1)!==i? '1px solid':''}} className='px-2 py-25 py-md-2  text-center my-auto text-black eqpro'>
                           {item.title}
                       </MDBCol>
                )
                    
            :null}
            {/* <MDBCol onClick={() => scrollIntoID("atıştırmak")} className='px-2 py-25 py-md-2  text-center my-auto text-black eqpro'>
                    {t("Atıştırmak")}
                </MDBCol>
                <MDBCol onClick={() => scrollIntoID("burger")} style={{borderRight:'1px solid',borderLeft:'1px solid'}} className='px-2  py-25 py-md-2 text-center my-auto text-black eqpro'>
                    {t("BURGER")}
                </MDBCol>
                <MDBCol onClick={() => scrollIntoID("tavuk")} style={{borderRight:'1px solid'}} className='px-2  py-25 py-md-2 text-center my-auto text-black eqpro'>
                    {t("TAVUK ve HOT DOG")}
                </MDBCol>
                <MDBCol onClick={() => scrollIntoID("vegan")} style={{borderRight:'1px solid'}} className='px-2 py-25 py-md-2 text-center my-auto text-black eqpro'>
                    {t("Vegan")}
                </MDBCol>
                <MDBCol onClick={() => scrollIntoID("icicekler")} className='px-2  py-25 py-md-2 text-center my-auto text-black eqpro'>
                    {t("İçecek")}
                </MDBCol> */}
                {/* <MDBCol onClick={() => scrollIntoID("breakfast")} className='px-2 py-2 my-auto'>
                {t("Kahvaltı")}
                </MDBCol> */}
            </MDBRow>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        Types: state.Types.Data,
        TypesLoading: state.Types.Loading
    }
}

export default withRouter(connect(mapStateToProps)(HomeMenu))

