import  React,{ Fragment, useState } from 'react';
import {
  MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBNav, MDBNavItem, MDBNavLink, MDBInput, MDBModalFooter,
  MDBIcon, MDBTabContent, MDBTabPane, MDBRow,MDBModalHeader,MDBCol
} from 'mdbreact';
import Login from './Login';
import { useTranslation } from 'react-i18next';
// import LoginRegister from './LoginRegister';

const LoginRegisterModal = (props) => {
  const { t, i18n } = useTranslation();
  let [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  const close = () => {
    setModal(false);
  }

  return (
    <Fragment>
      <span id="openLoginModal" className="d-none" rounded onClick={()=>{
        toggle()
        }}>Login/Register</span>
      <MDBModal id="LoginRegisterModal" className="form-cascading modal-notify ltr" isOpen={modal} toggle={toggle}>
      <MDBModalHeader className='rounded-zolfa-top-right bg-light rounded-zolfa-top-left' toggle={toggle}>
      <MDBRow>
                        <MDBCol className='text-center'>
                           
                            <h5 className='text-black m-0'> <i className={`pr-1 fa fa-user`}></i>{t("Giriş yap/Kayıt ol")}</h5>
                        </MDBCol>
                    </MDBRow>
                               
          </MDBModalHeader>
          <MDBModalBody className='rounded-zolfa-bottom-right rounded-zolfa-bottom-left p-0 bg-white'>
            <Login />
            </MDBModalBody>
  
        <p onClick={close} className="d-none" id="closeLoginModal">Close</p>
      </MDBModal>
    </Fragment>
  );
}

export default LoginRegisterModal;