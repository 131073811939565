import * as type from './../../actions/ActionTypes';
const initialState = {
    Data: [],
    Loading: true
}

export function Foods(state = initialState, action) {
    switch (action.type) {
        case type.Food_List:
            return {
                ...state,
                Data: action.payload,
                Loading: false
            }      
        default:
            return state
    }
}

export function Types(state = initialState, action) {
    switch (action.type) {
        case type.FoodType_List:
            return {
                ...state,
                Data: action.payload,
                Loading: false
            }      
        default:
            return state
    }
}

export function ShoppingCart(state = [], action) {
    switch (action.type) {
        case type.Cart:
            return [
                ...state,
                action.payload
            ] 
        default:
            return state
    }
}