import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from
  'mdbreact';

class ModalMessage extends Component {
  state = {
    modal: false,
    message: '',
    title: '',
    class: 'info',
    close: 'ok',
    icon: null,
    onClose: null,
    button1: null,
    onButton1Click: null
  }

  toggle = (data = this.state.message, title = this.state.title, btnClose = this.state.close, modalClass = this.state.class, icon = this.state.icon, onClose = this.state.onClose, button1 = this.state.button1, onButton1Click = this.state.onButton1Click) => {
    this.setState({
      modal: !this.state.modal,
      message: data,
      close: btnClose ?? this.state.close,
      class: modalClass ?? this.state.class,
      title: title,
      icon: icon,
      onClose: onClose,
      button1: button1,
      onButton1Click: onButton1Click
    });
  }
  closeModal = () => {
    this.setState({ modal: false })
    if (this.state.onClose) {
      this.state.onClose()
    }
  }
  onButton1Click = () => {
    this.setState({ modal: false })
    if (this.state.onButton1Click) {
      this.state.onButton1Click()
    }
  }
  render() {
    return (
      <MDBContainer>
        <MDBModal className={`form-cascading modal-notify ltr`} isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader className={`rounded-zolfa-top-right bg-${this.state.class} rounded-zolfa-top-left text-white`} tag="p" toggle={this.closeModal}>{this.state.title}</MDBModalHeader>
          <MDBModalBody className='p-2 bg-white'>
            <div className='text-center'>
              {this.state.icon ?
                <MDBIcon icon={this.state.icon} size="4x" className="animated rotateIn mb-4" />
                : null
              }
            </div>
            <p className="" dangerouslySetInnerHTML={{ __html: this.state.message }}></p>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center rounded-zolfa-bottom-right rounded-zolfa-bottom-left ">
            <MDBBtn className='btn-rounded' color={this.state.class} onClick={this.closeModal}>{this.state.close}</MDBBtn>
            {this.state.button1 ?
              <MDBBtn className='btn-rounded' color={this.state.class} onClick={this.onButton1Click}>{this.state.button1}</MDBBtn>
              :
              null
            }
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalMessage;