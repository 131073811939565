import React, { Fragment } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBBtn } from "mdbreact";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as staticValue from './../staticValue';
import { useTranslation } from 'react-i18next';
import Iban from './Iban'
import Iframe from 'react-iframe'
const Footer = (props) => {
    const { t, i18n } = useTranslation();
    const { SettingsLoading, Settings } = props
    const changeMobile=(mobile)=>{
        if(mobile.startsWith("0")){
            mobile=mobile.substr(1)
        }
        return mobile
    }
    return (
        <Fragment>

            <MDBFooter  className="font-small darken-2 pt-0 position-relative mt-5 bg-light">
            {Settings ?
                                            <div className="divSocialFooter">
                                                {Settings.whatsapp?
                                                <a className='mr-2' target='_blank' href={`whatsapp://send?phone=${Settings ? Settings.whatsapp : ''}&text=`}>
                                                <img className='mt-2' src='/images/whatsapp.png' style={{width:'35px'}} />
                                                </a>
                                                :
                                                null
                                            }
                                                 {Settings.instagram?
                                               <a  target='_blank' href={`https://www.instagram.com/${Settings ? Settings.instagram : ''}`}>
                                               <img className='mt-2' src='/images/insta.png' style={{width:'35px'}} />
                                               </a>
                                                :
                                                null
                                            }
                                            </div>
                                            :
                                            null
                                        }
                <MDBContainer fluid className="text-center text-md-left">

                    <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4">

                        <MDBCol md="8" sm="12" className="mt-5">
                            {Settings ? <Fragment>

                                <a href={`tel:${Settings.mobile}`} target="_blank" className="text-black font-weight-bold" style={{ lineHeight: "1.7rem", fontSize: "1.5em" }}>
                                    {Settings.mobile}
                                </a>
                                <p className="text-black eqpro">{Settings.address}</p>
                            </Fragment> : null

                            }

                        </MDBCol>
                      <MDBCol id="googlemap" size="12">
                      <Iframe style={{borderRadius: '15px'}} url={Settings?Settings.footer:''}
                            width="82%"
                            height="300px"
                            id="myId"
                            className="rounded-zolfa"
                            display="initial"
                            position="relative" />
                      </MDBCol>

                    </MDBRow>

                    {/* <Iban className="text-center rtl" /> */}
                </MDBContainer>

                {/* <hr /> */}
         
                <div className="footer-copyright text-black text-center py-3 mt-3">
                    <MDBContainer fluid>
                        &copy; {new Date().getFullYear()} <a className="text-black font-weight-bold" href={staticValue.siteUrl}>{Settings?Settings.title:''}</a>
                    </MDBContainer>
                </div>
            </MDBFooter>
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        SettingsLoading: state.Settings.Loading,
        Settings: state.Settings.Data,

    }
}

export default connect(mapStateToProps)(Footer)