import axios from 'axios';
import * as ActionTypes from './../ActionTypes';
import configs from '../../config';
export const GetCurrentProfile = (token) => async dispatch => {
    try {
        const result = await axios.post(`${configs.siteApiUrl}/api/user/GetCurrentProfile`,null,{headers:{
            Authorization:'Bearer '+token
          }});
        dispatch({
            type: ActionTypes.Profile_CurrentUser,
            payload: result.data
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.Profile_CurrentUser,
            payload: null
        })
    }

}

export const SetIsSignedIn = (token) => async dispatch =>{
    try {
        const result = await axios.post(`${configs.siteApiUrl}/api/user/IsSignedIn`,null,{headers:{
            Authorization:'Bearer '+token,
            "Access-Control-Allow-Origin":"*"
          }});
        dispatch({
            type: ActionTypes.Profile_IsSignedIn,
            payload:result.data
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.Profile_IsSignedIn,
            payload:false
        })
    }

}

export const SetCurrentProfileRoles = () => async dispatch =>{
    const result = await axios.post(configs.siteApiUrl+'/api/user/GetCurrentProfileRoles');
    dispatch({
        type: ActionTypes.Profile_CurrentUser_Roles,
        payload:result.data
    })
}

export const SetAccessToken = value => {
    return {
        type: ActionTypes.Profile_AccessToken,
        value
    }
}