import React,{useState,Fragment,useRef,useEffect} from 'react'
import  { Redirect } from 'react-router-dom';
import ModalMessage from './../ModalMessage';
import { useCookies } from 'react-cookie';
import helpers from './../../helpers';
import AjaxForm from './../AjaxForm';
import {connect} from 'react-redux';
import { GetCurrentProfile, SetIsSignedIn,SetAccessToken } from './../../actions/profile/index';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import configs from '../../config';
import { MDBCol } from 'mdbreact';
import axios from 'axios';
import OTPCode from './OTPCode';
const Login = (props) => {
    const [cookies, setCookie] = useCookies();
    let [second, setSecond] = useState(60);
    let [startedTimer, setStartedTimer] = useState(false);
    let [step, setStep] = useState("mobile");
    let queryParamParse = queryString.parse(window.location.search);
    const queryParam = Object.keys(queryParamParse).reduce((c, k) => (c[k.toLowerCase()] = queryParamParse[k], c), {});
    const { t, i18n } = useTranslation();
    let [values, setValues] = useState({ Mobile: "",Code:"",ProfileID:"" })
    let [errors, setErrors] = useState({});
    const modalMessageRef = useRef();
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }
    const beforeSubmit = () => {
        const ValidateErrors = handleValidate();
        setErrors(ValidateErrors)
        if (Object.keys(ValidateErrors).length > 0) {
            return false;
        }
    }
    const beforeSubmitCode = () => {
        const ValidateErrors = handleValidateCode();
        setErrors(ValidateErrors)
        if (Object.keys(ValidateErrors).length > 0) {
            return false;
        }
    }
    const handleValidate = () => {
        let errors = {};
        if (!values.Mobile) {
            errors.Mobile = t("telefon gerekli");
        }
        if(values.Mobile){
            if(values.Mobile.length<9){
                errors.Mobile = t("Cep telefonu numarası doğru değil");
            }
        }
        return errors;
    }
    const handleValidateCode = () => {
        let errors = {};
        if (!values.Code) {
            errors.Mobile = t("telefon gerekli");
        }
        if(values.Code){
            if(values.Code.length<4){
                errors.Mobile = t("Cep telefonu numarası doğru değil");
            }
        }
        return errors;
    }
    const Done = (data) => {
        setValues({...values,ProfileID:data})
setStep("code");
if (!startedTimer) {
    startTimer()
    setStartedTimer(true)
}
    }
    const ResendCode = () => {
        if (second == 0) {
            setSecond(60)
            setStartedTimer(false)
            let formdata=new FormData();
            formdata.append("SubscriptionID",configs.subscriptionID)
            formdata.append("Mobile",values.Mobile)
            axios.post(configs.siteApiUrl+'/api/user/SendCodeToMobile', formdata)
            .then(function (response) {
                if(response.data.success){
                   
                }else{
                    Fail(response.data.data)
                }
              });
        }
    }
    const DoneCode = (token) => {
        setCookie('AccessToken', token, { path: '/', expires: helpers.AddDays(Date.now(), 7) });
        props.SetIsSignedIn(token);
        props.SetAccessToken(token);
        props.GetCurrentProfile(token);
        if (document.getElementById("closeLoginModal")) {
            document.getElementById("closeLoginModal").click()
        }
        setStep("mobile");
                if (props.location) {
                    if (queryParam.returnurl) {
                        window.location.href = queryParam.returnurl;
                    } else {
                  let urlIndex=props.location.search.indexOf('=')
                  window.location.href=urlIndex
                    }
              }
            }
    const Fail = (data) => {
        modalMessageRef.current.toggle(data,'Error',null,'danger','exclamation')
    }
    const startTimer = () => {
        console.log('timer')
        let timer = setInterval(() => {
            if (second > 0) {
                setSecond((--second))
            } else {
                clearInterval(timer);
            }
        }, 1000);
    }
    useEffect(() => {
        if (!startedTimer && step=='code') {
            startTimer()
            setStartedTimer(true)
        }
    }, [startedTimer])
    return (
        <Fragment>
 <ModalMessage ref={modalMessageRef} />
    <div id="LoginRegisterDiv" className="row m-0">
        <div className="col-12 ltr px-0">
            {step=="mobile"?
                     <AjaxForm className="m-0" api={configs.siteApiUrl+'/api/user/SendCodeToMobile'} onFail={Fail} onSuccess={Done} beforeSubmit={beforeSubmit}>
                     <div className="col-12 my-2 text-left">
                         <label className=" mb-0 eqpro">{t("Cep Telefonu Numarası")}</label>
                         <input onChange={handleChange} name="Mobile" value={values.Mobile} type='number' className="form-control ltr text-left bg-light" />
                         {errors.Mobile && <p className='text-danger'>{errors.Mobile}</p>}
                     </div>

                 <MDBCol size='12' className={`d-flex ${props.shoppingCart?'justify-content-center':'justify-content-center'} rounded-zolfa-bottom-right rounded-zolfa-bottom-left py-3`}>
                 <button className="btn btn-orange btn-rounded  waves-effect z-depth-0 eqpro" type="submit">{t("Şifre Gönder")}</button>
                 </MDBCol>

                 </AjaxForm> 
        :
        null}
        {step=="code"?
               <AjaxForm className="m-0" api={configs.siteApiUrl+'/api/user/ConfirmCode'} onFail={Fail} onSuccess={DoneCode} beforeSubmit={beforeSubmitCode}>
                <div className='col-12 mt-2 text-center py-0 d-flex justify-content-between flex-center mb-2'>
                    {/* <i style={{fontSize:'25px',color:'#f57c00'}} onClick={()=>setStep("mobile")} className='fa fa-arrow-alt-circle-left c-pointer'></i> */}
                    <p className='eqpro'>{t("Lütfen")} {values.Mobile} {t("hattanıza gelen tek kullanmalık Şifreyi giriniz")}.</p>
                    <p></p>
                </div>
               <div className="col-12 text-left">
             {/* <OTPCode /> */}
             <label className=" mb-0 eqpro">{t("Şifre")}</label>
                                      <input onChange={handleChange} name="Code" value={values.Code} type='number' className="form-control ltr text-left bg-light" />

               <input name="Mobile" type='hidden' value={values.Mobile} />
               <input name="ProfileID" type='hidden' value={values.ProfileID} />
                   {errors.Code && <p className='text-danger'>{errors.Code}</p>} 
               </div>
               <div className='col-12'>
               <span onClick={ResendCode} className={`eqpro c-pointer ${second > 0 ?'':'text-info'}`} >
                            {second>0?(t("Kalan Süre")):(t("Tekrar Gönder"))} {second > 0 ? (second +" "+t("DK")): ''}
                        </span>
               </div>

           <MDBCol size='12' className={`d-flex ${props.shoppingCart?'justify-content-between':'justify-content-between'} rounded-zolfa-bottom-right rounded-zolfa-bottom-left py-3`}>
           <span className="btn btn-danger-c btn-rounded   waves-effect z-depth-0 eqpro" onClick={()=>setStep("mobile")}>{t("Geri")}</span>
           <button className="btn btn-orange btn-rounded   waves-effect z-depth-0 eqpro" type="submit">{t("Giriş Yap")}</button>
           </MDBCol>

           </AjaxForm> 
    :
    null    
    }
       
</div>
</div>
        </Fragment>
       
    )
}


const mapDispatchToProps = dispatch => {
    return {
        GetCurrentProfile: (token) => dispatch(GetCurrentProfile(token)),
        SetIsSignedIn: (token) => dispatch(SetIsSignedIn(token)),
        SetAccessToken: (token) => dispatch(SetAccessToken(token))
    }
}

export default connect(null, mapDispatchToProps)(Login)