import axios from 'axios';
import * as ActionTypes from './../ActionTypes';
import configs from '../../config';
export const GetSetting = () => async dispatch => {
    let bodyFormData = new FormData();
    bodyFormData.set('SubscriptionID', configs.subscriptionID);
    const result = await axios.post(configs.siteApiUrl+'/api/Setting/ContactSetting',bodyFormData);
    dispatch({
        type: ActionTypes.Setting_List,
        payload: result.data
    })
}
export const SetSearch = value => {

    return {
        type: ActionTypes.Search,
        value
    }
}