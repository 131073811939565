
import React, { useEffect } from 'react';
import Loading from './../Loading';
const Logout = (props) => {
    useEffect(() => {
        document.getElementById('LoguotForm').submit();
        return () => { }
    }, []);
    return (
        <div className='mt-5 pt-5'>
            <div className='mt-5 pt-5 text-center mb-5 pb-5'>
                <Loading />
            </div>
            <form id='LoguotForm' action='/Identity/Account/Logout?returnurl=/'>

            </form>
        </div>
    )
}

export default Logout
