import React, { Component, Fragment, useState, useRef } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as staticValue from './../staticValue';
import Cart from './Food/Cart';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import SideNav, { MenuIcon } from 'react-simple-sidenav';
import { useTranslation } from 'react-i18next';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer, MDBIcon, MDBRow
} from "mdbreact";
import LoginMenuMobile from './Profile/LoginMenuMobile';


const NavMenu = (props) => {
    const [cookies, setCookie] = useCookies();
    const [showNav, setShowNav] = useState();
    const { Settings, SettingsLoading, IsSignedIn,Types,TypesLoading } = props;
    let [collapsed, setCollapsed] = useState(true);
    let [collapseID, setCollapseID] = useState("");
    const { t, i18n } = useTranslation();
    const toggleCollapse = (ID) => {
        if (collapseID != ID) {
            setCollapseID(ID)

        } else {
            setCollapseID("")
        }
    }


    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }

    const handleClick = (lang) => {
        i18n.changeLanguage(lang)
    }


    const scrollIntoID = (id) => {
        let elm = document.getElementById(id)
        if (elm) {
            elm.scrollIntoView({ behavior: 'smooth' })
            setShowNav(false)
        } else {
            props.history.push("/#" + id)
            setShowNav(false)
        }
    }
    return (
        <header id="Header" data-loc={props.location} className=''>
            <MDBContainer>
                <MDBRow>
                    <MDBNavbar id='HomeMenuNav' className='rtl col-12 darken-3 pt-3 pb-2 noHomeMenu' color='elegant-color' expand='md' fixed='top' scrolling >

                        <MDBNavbarNav className='d-inline d-md-none' left>
                            <span onClick={() => setShowNav(true)} className='btn btn-floating btn-white'> <i className="fa fa-bars fa-2x d-md-none" style={{ zIndex: '9999', width: '40px', height: '40px', color: "#000" }} ></i></span>
                            <SideNav
                                showNav={showNav}
                                onHideNav={() => setShowNav(false)}
                                title={<span onClick={() => setShowNav(false)} className="c-pointer"><i className="fa fa-times"></i></span>}
                                titleStyle={{ backgroundColor: cookies.dark == "on" ? '#212121' : '#fff', padding: '0px 15px', color: '#ff9800' }}
                                navStyle={{ width: '100%', maxWidth: '250px', listStyle: 'none', backgroundColor: cookies.dark == "on" ? '#212121' : '#fff' }}
                                items={[
                                    !TypesLoading?
                                        Types.map((item, i) =>
                                            <p className="c-pointer font-weight-bold eqpro w-100 h-100 pb-4" onClick={() => scrollIntoID(item.title)}>{item.title}</p>
                             ):null,
                                    // <span className="c-pointer font-weight-bold" onClick={() => scrollIntoID("breakfast")}>{t("صبحانه")}</span>,
                                    // <p className="c-pointer font-weight-bold eqpro w-100 h-100" onClick={() => scrollIntoID("atıştırmak")}>{t("Atıştırmak")}</p>,
                                    // <p className="c-pointer font-weight-bold eqpro w-100 h-100" onClick={() => scrollIntoID("burger")}> {t("BURGER")}</p>,
                                    // <p className="c-pointer font-weight-bold eqpro w-100 h-100" onClick={() => scrollIntoID("tavuk")}>{t("TAVUK ve HOT DOG")}</p>,
                                    // <p className="c-pointer font-weight-bold position-relative eqpro w-100 h-100" onClick={() => scrollIntoID("vegan")}> {t("Vegan")}</p>,
                                    // <p className="c-pointer font-weight-bold eqpro w-100 h-100" onClick={() => scrollIntoID("icicekler")}> {t("İçecek")}</p>,
                                    <p className=" font-weight-bold border-0"> </p>,
                                    <LoginMenuMobile closeFunc={setShowNav} />,
                                
                             
                                    <div className="my-2">

                                        <div className='px-0 ltr font-weight-bold' style={{ 'font-size': '0.8em' }}>
                                            {SettingsLoading ?
                                                <i className='fa fa-spin fa-spinner text-warning'></i>
                                                :
                                                Settings ?
                                                    <a target='_blank' href={`tel:${Settings.mobile}`} style={{ fontSize: '1.1em', color: '#ff9800' }} className=''>
                                                        <i className='fa fa-phone mr-1'></i>
                                                        {Settings.mobile}
                                                    </a> : null
                                            }

                                        </div>
                                        <div className='d-flex ltr mt-2' style={{ 'font-size': '0.8em', color: '#ff9800' }}>
                                            <i className='fa fa-map mr-1'></i>
                                            <a target='_blank' href={Settings?Settings.footer:''} className="">  <p style={{ color: '#ff9800' }} className="small eqpro">{Settings ? Settings.address : ''}</p></a>
                                        </div>
                                        {Settings ?
                                            <Fragment>
                                                {Settings.whatsapp?
                                                <a className='' target='_blank' href={`whatsapp://send?phone=${Settings ? Settings.whatsapp : ''}&text=`}>
                                                <img className='mt-2' src='/images/whatsapp.png' style={{width:'35px'}} />
                                                </a>
                                                :
                                                null
                                            }
                                                 {Settings.instagram?
                                               <a className='mr-3' target='_blank' href={`https://www.instagram.com/${Settings ? Settings.instagram : ''}`}>
                                               <img className='mt-2' src='/images/insta.png' style={{width:'35px'}} />
                                               </a>
                                                :
                                                null
                                            }
                                            </Fragment>
                                            :
                                            null
                                        }
                                    </div>
                                    ,
                                ]}

                            />
                        </MDBNavbarNav>
                    </MDBNavbar>
                </MDBRow>
            </MDBContainer>

        </header>
    );

}


const mapStateToProps = state => {
    return {
        IsSignedIn: state.IsSignedIn.Value,
        CurrentProfile: state.CurrentProfile,
        Settings: state.Settings.Data,
        SettingsLoading: state.Settings.Loading,
        Types: state.Types.Data,
        TypesLoading: state.Types.Loading
    }
}

export default withRouter(connect(mapStateToProps)(NavMenu))
