import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    MDBNavItem, MDBNavLink, MDBIcon
} from "mdbreact";
import { Link } from 'react-router-dom';
const LoginMenu = (props) => {
    const { t, i18n } = useTranslation();
    let { IsSignedIn, IsSignedInLoading, CurrentProfile, CurrentProfileRoles, CurrentProfileRolesLoading } = props;
    return (
        <Fragment>
            {IsSignedInLoading?<span><i className='fa fa-spin fa-spinner text-warning'></i></span>:
                        IsSignedIn ?
                        <Fragment>
                            {/* <MDBNavItem>
                                <MDBNavLink tag={Link} to="/Logout"> <MDBIcon icon="sign-out-alt" className="mr-1" />  {t("خروج")}</MDBNavLink>
                             
                            </MDBNavItem>  */}
                                <Link onClick={()=>props.closeFunc(false)} to="/Profile">
                                    <p className="c-pointer font-weight-bold text-black eqpro w-100 h-100">{t("Profil")}</p>
                                </Link>
                                
</Fragment>
                            :
                            <Fragment>
                            
                                    <p onClick={()=> document.getElementById("openLoginModal").click()} className="c-pointer font-weight-bold border-0 w-100 h-100"><span className='eqpro'>{t("Giriş yap/Kayıt ol")}</span><i className='fa fa-user mr-2 text-success'></i></p>
                            </Fragment>
                        }
        </Fragment>
    )
}


const mapStateToProps = state => {
    return {
        IsSignedIn: state.IsSignedIn.Value,
        IsSignedInLoading: state.IsSignedIn.Loading,
        CurrentProfile: state.CurrentProfile,
        CurrentProfileRoles: state.CurrentProfileRoles.Value,
        CurrentProfileRolesLoading: state.CurrentProfileRoles.Loading,
    }
}

export default connect(mapStateToProps)(LoginMenu)

