﻿import React, {  useEffect } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import * as staticValue from './staticValue';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { GetCurrentProfile, SetIsSignedIn, SetCurrentProfileRoles } from './actions/profile/index';
import Details from './components/Food/Details';
import Login from './components/Profile/Login';
import Logout from './components/Profile/Logout';
import './mdb-rtl.css';
import 'react-image-lightbox/style.css';
import './style.css';
import "./DatePicker.css";
import { GetFoodList,GetTypeList } from './actions/Food';
import { GetSetting } from './actions/Website';
import './AnimatedShoppingCart.css';
import ShoppingCart from './components/Food/ShoppingCart'
import ProfileInfo from './components/Profile/ProfileInfo';
import ProfileOrders from './components/Profile/ProfileOrders';
import ProfileAddresses from './components/Profile/ProfileAddresses';
import configs from './config';
const App =(props)=> {
  const [cookies, setCookie] = useCookies();
useEffect(() => {
  document.getElementsByTagName('title')[0].innerHTML = configs.siteTitle;
  document.querySelectorAll('[name="apple-mobile-web-app-title"]')[0].content=configs.siteTitle;
  document.querySelectorAll('[name="keywords"]')[0].content=configs.siteKeyword;
  document.querySelectorAll('[name="description"]')[0].content=configs.siteDescription;
  if ((props.IsSignedInLoading) && (!props.IsSignedIn)) {
    props.SetIsSignedIn(cookies.AccessToken);
    props.GetCurrentProfile(cookies.AccessToken);
}
  props.GetFoodList();
  props.GetTypeList();
  props.GetSetting();
  return () => {
    
  }
}, [])



    return (
      <Layout>
        <Route exact path='/' component={Home} />
            <Route path='/Login' component={Login} />
            <Route path='/Logout' component={Logout} />
            <Route path='/Food/:permalink' component={Details} />
            <Route path='/Cart' component={ShoppingCart} />
            <Route path='/Profile' component={ProfileInfo} />
            <Route path='/Orders' component={ProfileOrders} />
            <Route path='/MyAddress' component={ProfileAddresses} />
      </Layout>
    );
  }



const mapDispatchToProps = dispatch => {
  return {
    GetCurrentProfile: (token) => dispatch(GetCurrentProfile(token)),
    SetIsSignedIn: (token) => dispatch(SetIsSignedIn(token)),
    GetFoodList: () => dispatch(GetFoodList()),
    GetTypeList: () => dispatch(GetTypeList()),
    GetSetting:()=>dispatch(GetSetting())
  }
}
const mapStateToProps = state => {
  return {
      IsSignedIn: state.IsSignedIn.Value,
      IsSignedInLoading: state.IsSignedIn.Loading,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)