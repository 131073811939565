const env = process.env.NODE_ENV || 'development';

const configs = {
  development: {
    siteUrl:"https://ZolfaFood.com",
    siteFileUrl:"https://admin.888pos.com/files",
    siteApiUrl: 'http://localhost:64291', //http://localhost:64291
    siteTitle:"ZolfaFood",
    siteTitleShort:"ZolfaFood",
    siteDescription:"restaurant",
    siteKeyword:"Iranian restaurant, vegan restaurant",
    subscriptionID:"64eb11ae-2acb-46ce-593f-08dbca4d601a", //64eb11ae-2acb-46ce-593f-08dbca4d601a
    siteImage:"https://coreapi.DCABot.com/Images/logo.png"
  },
  production: {
    siteUrl:"https://zolfafood.com", //http://7khan.888pos.com , https://zolfafood.com
    siteFileUrl:"https://admin.888pos.com/files",
    siteApiUrl: 'https://admin.888pos.com',
    siteTitle:"Zolfafood", //7khan , Zolfafood
    siteTitleShort:"Zolfafood", //7khan , Zolfafood
    siteDescription:"restaurant",
    siteKeyword:"Iranian restaurant, vegan restaurant",
    subscriptionID:"6dd45582-1baa-4682-8d18-826d7cebea84", //24e071ee-60bf-40dd-a2dc-b31fb92c5c2a ,6dd45582-1baa-4682-8d18-826d7cebea84
    siteImage:"http://localhost:64291/Images/logo.png"
  },
}[env];

export default configs;