import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import Loading from './../Loading';
import FoodCart from './FoodCart';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import 'react-toastify/dist/ReactToastify.css';
import { GetSetting } from './../../actions/Website';
import { GetFoodList } from './../../actions/Food';
import { ToastContainer, toast } from 'react-toastify';
import configs from '../../config';
import Iban from './../Iban';
import {
    MDBBtn, MDBIcon, MDBAlert, MDBModal, MDBModalHeader, MDBModalBody,
    MDBCol, MDBContainer, MDBRow, MDBInput, MDBFormInline, MDBCard, MDBModalFooter
} from 'mdbreact';
import Link from '../../MDBproComponents/Link/Link';
import Row from '../../MDBproComponents/Row/Row';
import ModalMessage from '../ModalMessage';
import Login from '../Profile/Login';
import MyAddress from '../Profile/MyAddress';
const ShoppingCart = (props) => {
    let queryParamParse = queryString.parse(window.location.search);
    const queryParam = Object.keys(queryParamParse).reduce((c, k) => (c[k.toLowerCase()] = queryParamParse[k], c), {});
    const modalMessageRef = useRef();
    const [step, setStep] = useState("cart")
    let [arabicChar, setArabicChar] = useState(new RegExp('^[\p{Arabic}\s\p{N}]+$'))
    let [orderModal, setOrderModal] = useState(false);
    let [confirmModal, setConfirmModal] = useState(false);
    const mobileRef = useRef(null);
    const [noSpoon, setNoSpoon] = useState(false);
    const [packetSalon, setPacketSalon] = useState(false);
    const { t, i18n } = useTranslation();
    const { Settings, closeCartModal, IsSignedIn, CurrentProfile } = props;
    let [table, setTable] = useState(null);
    const [cookies, setCookie] = useCookies();
    let [whatsappLink, SetWhatsapplink] = useState('')
    let [submitLoading, setSubmitLoading] = useState(false)
    let [items, setItems] = useState([]);
    let [total, setTotal] = useState(0);
    let [payment, setPayment] = useState("cash");
    let [type, setType] = useState("paket");
    let [description, setDescription] = useState('');
    let [name, setName] = useState('');
    let [lastName, setLastName] = useState('');
    let [userNote, setUserNote] = useState('');
    let [mobile, setMobile] = useState('');
    let [blok, setBlok] = useState('');
    let [kat, setKat] = useState('');
    let [daire, setDaire] = useState('');
    let [mobileError, setMobileError] = useState('')
    let { Foods, FoodsLoading } = props;
    let [userCountry, setUserCountry] = useState('')

    useEffect(() => {

        if (Settings) {
            if (Settings.disablePaket) {
                setType("salon")
            } else if (Settings.disableSalon) {
                setType("paket")
            }
            if ((Settings.disablePaket) && (Settings.disableSalon)) {
                setType(null)
            }
        }
    }, [Settings])

    useEffect(() => {
        if(queryParam.table){
            setType("salon")
            setTable(queryParam.table)
        }
    }, [])
    

    useEffect(() => {
        if(CurrentProfile){
            setName(CurrentProfile.firstName)
            setLastName(CurrentProfile.lastName)
            setMobile(CurrentProfile.mobile)
            if (CurrentProfile.profileAddresses) {
                if (CurrentProfile.profileAddresses.length > 0) {
                    let address = CurrentProfile.profileAddresses.find(x => x.default);
                    if (address) {
                        setDescription(address.address)
                        setBlok(address.blok)
                        setKat(address.kat)
                        setDaire(address.daire)
                        setName(address.firstName)
                        setLastName(address.lastName)
                    }
                }
            }
        }
    }, [CurrentProfile])


    useEffect(() => {
        props.GetSetting()
        let timer = setInterval(() => {
            props.GetSetting()
        }, 10000);
        return () => {
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        axios.get('https://extreme-ip-lookup.com/json/').then(res => {
            console.log(res.data.country)
            setUserCountry(res.data.country)
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (cookies.CartItems) {
                if (cookies.CartItems.length > 0) {
                    let foodsID = cookies.CartItems.map(x => x.id).toString();
                    let bodyFormData = new FormData();
                    bodyFormData.set('FoodsIDs', foodsID);
                    axios.post(configs.siteApiUrl + '/api/food/CheckFoods', bodyFormData).then(res => {
                        if (!res.data.success) {
                            let jsonCartItems = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')
                            for (const item of res.data.data) {
                                jsonCartItems = jsonCartItems.filter(x => x.id != item)
                            }
                            let tomorrow = new Date();
                            tomorrow.setDate(tomorrow.getDate() + 1);
                            setCookie('CartItems', JSON.stringify(jsonCartItems), { path: '/', expires: tomorrow });
                            if (modalMessageRef.current) {
                                modalMessageRef.current.toggle(res.data.message, '', null, 'danger', 'exclamation', () => { window.location.reload() })
                            }
                            props.GetFoodList();
                        }
                    })
                }
            }
        }, 3000);
        return () => {
        }
    }, [])

    useEffect(() => {

        // console.log('shoppingCart')
        // window.scrollTo(0, 0);

        let items = [];
        let amount = 0;
        if (!FoodsLoading) {
            if (cookies.CartItems) {
                for (const item of cookies.CartItems) {
                    let additionalPrice=0;
                    let foodObj = Foods.find(x => x.foodID == item.id);
                    if (foodObj) {
                        foodObj['count'] = item.count
                        if(item.selectedOptions){
                            foodObj['selectedOptions']=item.selectedOptions;
                            foodObj['additionalPrice']=item.additionalPrice;
                            additionalPrice=item.additionalPrice;
                        }
                        items.push(foodObj)
                        amount += ((parseFloat(foodObj.price)) * (parseFloat(foodObj.count)))+additionalPrice
                    }
                }
            }

            setItems(items);
            setTotal(amount);

        }
        return () => {
            setItems([])
        }
    }, [Foods, cookies.CartItems]);

    useEffect(() => {
        setTimeout(() => {
            document.getElementById('divOrder').scrollIntoView({ behavior: 'smooth' })
        }, 700);
        //console.log('time '+time)
    }, [])
    const stepOrder = () => {
        setStep("order")
        $('#stepCart').fadeOut(500, () => {
            $('#stepOrder').fadeIn(500)
        });


    }



    const submitOrder = () => {
        setSubmitLoading(true)
        let error = false;

        if (type == "salon") {
            if ((!table) && (!packetSalon)) {

                setMobileError(t('Lütfen masanızı seçin'))
                // document.getElementById('mobile').classList.add('errorfocus')
                error = true
            }
        } else {
            if (mobile) {
                if (mobile.length < 10) {
                    document.getElementById('mobile').scrollIntoView({ behavior: 'smooth' })
                    mobileRef.current.focus()
                    setMobileError(t('Telefon numarası doğru değil'))
                    document.getElementById('mobile').classList.add('errorfocus')
                    error = true
                }
                if (!((mobile.charAt(1) == "5") || (mobile.charAt(0) == "5"))) {
                    setMobileError(t('Telefon numarası doğru değil'))
                    document.getElementById('mobile').classList.add('errorfocus')
                    error = true
                }
            } else {
                document.getElementById('mobile').scrollIntoView({ behavior: 'smooth' })
                mobileRef.current.focus()
                setMobileError(t('telefon gerekli'))
                document.getElementById('mobile').classList.add('errorfocus')
                error = true
            }
            // if (!name) {
            //     setMobileError(t('İsim gerekli'))
            //     document.getElementById('FullName').classList.add('errorfocus')
            //     error = true
            // } else {
            //     document.getElementById('FullName').classList.remove('errorfocus')
            // }
            // if (!lastName) {
            //     setMobileError(t('soyadı gerekli'))
            //     document.getElementById('LastName').classList.add('errorfocus')
            //     error = true
            // } else {
            //     document.getElementById('LastName').classList.remove('errorfocus')
            // }
            // if (!blok) {
            //     setMobileError('وارد کردن بلاک اجباری است')
            //     document.getElementById('Blok').classList.add('errorfocus')
            //     error = true
            // } else {
            //     document.getElementById('Blok').classList.remove('errorfocus')
            // }
            // if (!kat) {
            //     setMobileError(t('Kat')+' '+t('gerekli'))
            //     document.getElementById('Kat').classList.add('errorfocus')
            //     error = true
            // } else {
            //     document.getElementById('Kat').classList.remove('errorfocus')
            // }
            // if (!daire) {
            //     setMobileError(t('Daire')+ ' '+t('gerekli'))
            //     document.getElementById('Daire').classList.add('errorfocus')
            //     error = true
            // } else {
            //     document.getElementById('Daire').classList.remove('errorfocus')
            // }
            // if (!description) {
            //     setMobileError(t('Adres')+' '+t('gerekli'))
            //     document.getElementById('AddressDesc').classList.add('errorfocus')
            //     error = true
            // } else {
            //     if (description.length < 5) {
            //         setMobileError(t('Adresi doğru şekilde girin'))
            //         document.getElementById('AddressDesc').classList.add('errorfocus')
            //         error = true
            //     } else {
            //         document.getElementById('AddressDesc').classList.remove('errorfocus')
            //     }
            // }
                     if (!description) {
                setMobileError(t('Adres')+' '+t('gerekli'))
                error = true
            }
            
        }
        if (!error) {

            let link = `whatsapp://send?phone=${Settings ? Settings.orderMobile : ''}&text=`;
            let text = 'Merhaba %0a Siparişlerim :%0a %0a';
            for (const food of items) {
                text += food.title + ' - ' + food.count + ' Adet %0a'
            }
            text += '%0a' + total + ' ₺ %0a';
            // text += 'Ödeme Şekli : ' + payment;
            if (type == 'salon') {
                text += '%0a MASA : ' + table;
            }
            text += '%0a' + type;
            if (type == "paket") {
                if (mobile) {
                    text += '%0a %0a' + 'telefon numarası : ' + mobile;
                }
                if (name) {
                    text += (mobile ? '' : '%0a') + '%0a' + 'Ad : ' + name;
                }
                if (lastName) {
                    text += (name ? '' : '%0a') + '%0a' + 'Soyad : ' + lastName;
                }
                if (description) {
                    text += (lastName ? '' : '%0a') + '%0a' + 'Adres : ' + description;
                }
                if (userNote) {
                    text += (description ? '' : '%0a') + '%0a' + 'not : ' + userNote;
                }
                if (noSpoon) {
                    text += '%0a %0a' + 'Plastik kaşık ve çatal paketi gönderilmesin / Servis (plastik çatal , peçete) ISTEMIYORUM'
                }
            }
            // window.open(link + text);
            SetWhatsapplink(link + text)
            let bodyFormData = new FormData();
            bodyFormData.set('Content', text);
            if (type == "paket") {
                if(document.getElementById("cartAddress")){
                    let blokText = document.getElementById("cartBlok").innerText;
                    let katText = document.getElementById("cartKat").innerText;
                    let daireText = document.getElementById("cartDaire").innerText;
                    bodyFormData.set('Phone', mobile);
                    bodyFormData.set('Address', (document.getElementById("cartAddress").innerText) + (blokText.length > 0 ? (" Blok:" + blokText) : "") + " Kat:" + katText + " Daire:" + daireText);
                    bodyFormData.set('FullName', document.getElementById("cartFirstName").innerText);
                    bodyFormData.set('LastName', document.getElementById("cartLastName").innerText);
                   
                    bodyFormData.set('Blok', blokText);
                    bodyFormData.set('Kat', katText);
                    bodyFormData.set('Daire', daireText);
                }
            }
            bodyFormData.set('Description', userNote);
            bodyFormData.set('Type', type);
            // bodyFormData.set('PayMethod', payment);
            bodyFormData.set('NoSpoon', noSpoon);
            if (type == "salon") {
                bodyFormData.set('PacketSalon', packetSalon);
            }
            bodyFormData.set('Total', total);
            bodyFormData.set('Table', table);
            bodyFormData.set('SubscriptionID', configs.subscriptionID);
            bodyFormData.set('Items', JSON.stringify(items));
            axios.post(configs.siteApiUrl + '/api/food/AddOrder', bodyFormData).then(res => {
                setOrderModal(false)
                if (type == "paket") {
                    let Orders = cookies.Orders ? cookies.Orders : JSON.parse('[]')
                    Orders.push(res.data.data.orderID)
                    let today = new Date();
                    today.setHours(today.getHours() + 4);
                    setCookie('Orders', JSON.stringify(Orders), { path: '/', expires: today });
                }
                setTimeout(() => {
                    setStep("result")
                    $('#stepCart').fadeOut(500);
                    $('#stepOrder').fadeOut(500, () => {
                        $('#stepResult').fadeIn(500)
                    });
                    setCookie('CartItems', '[]', { path: '/' });
                    setItems([])
                    setTable(null)
                    setPayment("cash")
                    document.getElementById('spnShoppingCartCount').innerText = 0;
                    document.getElementById('spnShoppingCartPrice').innerText = '₺ ' + 0;
                }, 500);
            }).then(() => {
                setSubmitLoading(false)
            });
            setMobileError('')
            if (document.getElementById('mobile')) {
                document.getElementById('mobile').classList.remove('errorfocus')
            }
        } else {
            setSubmitLoading(false)
        }


    }
    const sendWhatsApp = () => {
        window.open(whatsappLink);
        // closeConfimModal()
    }
    const SendAndCall = () => {
        let link = `whatsapp://send?phone=${Settings ? Settings.orderMobile : ''}&text=`;
        let text = 'Merhaba %0a Siparişlerim :%0a %0a';
        for (const food of items) {
            text += food.titleTR + ' (' + food.title + ')' + ' - ' + food.count + ' Adet %0a'
        }
        text += '%0a' + total + ' ₺ %0a';
        text += 'Ödeme Şekli : ' + payment;
        if (mobile) {
            text += '%0a %0a' + 'telefon numarası : ' + mobile;
        }
        if (name) {
            text += (mobile ? '' : '%0a') + '%0a' + 'Ad/Soyad : ' + name;
        }
        if (description) {
            text += (name ? '' : '%0a') + '%0a' + 'Adres/Not : ' + description;
        }
        // window.open("tel:2123463846")
        let bodyFormData = new FormData();
        bodyFormData.set('Content', text);
        bodyFormData.set('SubscriptionID', configs.subscriptionID);
        axios.post(configs.siteApiUrl + '/api/food/AddOrder', bodyFormData).then(res => {
            // setCookie('CartItems', '[]', { path: '/' });
            // window.location.href = "/"
        });
    }

    const handleChangeDes = (e) => {
        let regex = /[\u0600-\u06FF]/
        if (!regex.test(e.target.value)) {
            setDescription(e.target.value)
            setCookie('userDescription', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        } else {
            setMobileError('Lütfen latin alfabesiyle yazın')
        }
    }

    const handleChangeName = (e) => {
        let regex = /[\u0600-\u06FF]/
        if (!regex.test(e.target.value)) {
            setName(e.target.value)
            if (e.target.value == '') {
                // document.getElementById('FullName').classList.remove('')        
            }
            setCookie('userFullName', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        } else {
            setMobileError('Lütfen latin alfabesiyle yazın')
        }
    }
    const handleChangeBlok = (e) => {
        let regex = /[\u0600-\u06FF]/
        if (!regex.test(e.target.value)) {
            setBlok(e.target.value)
            if (e.target.value == '') {
                // document.getElementById('FullName').classList.remove('')        
            }
            setCookie('blok', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        } else {
            setMobileError('Lütfen latin alfabesiyle yazın')
        }
    }
    const handleChangeKat = (e) => {
        let regex = /[\u0600-\u06FF]/
        if (!regex.test(e.target.value)) {
            setKat(e.target.value)
            if (e.target.value == '') {
                // document.getElementById('FullName').classList.remove('')        
            }
            setCookie('kat', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        } else {
            setMobileError('Lütfen latin alfabesiyle yazın')
        }
    }
    const handleChangeDaire = (e) => {
        let regex = /[\u0600-\u06FF]/
        if (!regex.test(e.target.value)) {
            setDaire(e.target.value)
            if (e.target.value == '') {
                // document.getElementById('FullName').classList.remove('')        
            }
            setCookie('daire', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        } else {
            setMobileError('Lütfen latin alfabesiyle yazın')
        }
    }
    const handleChangeLastName = (e) => {
        let regex = /[\u0600-\u06FF]/
        if (!regex.test(e.target.value)) {
            setLastName(e.target.value)
            if (e.target.value == '') {
                // document.getElementById('FullName').classList.remove('')        
            }
            setCookie('userLastName', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        } else {
            setMobileError('Lütfen latin alfabesiyle yazın')
        }
    }
    const handleChangeMobile = (e) => {
        let regex = /^[0-9]+$|^$|^\s$/
        if (regex.test(e.target.value)) {
            // mobileRef.current.className = "form-control rounded-0 mb-1 border-0 ltr";
            setMobile(e.target.value)
            setCookie('userMobile', JSON.stringify(e.target.value), { path: '/', expires: new Date(3000, 1, 1) });
        }
    }

    const handleChangePayment = (x) => {
        if (x == "IBAN") {
            $('#divIban').slideDown();
        } else {
            $('#divIban').slideUp();
        }
        setPayment(x);
    }

    const deleteAll = () => {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setCookie('CartItemsBackup', cookies.CartItems, { path: '/', expires: tomorrow })
        setCookie('CartItems', JSON.parse('[]'), { path: '/' });
        document.getElementById('spnShoppingCartCount').innerText = 0;
        document.getElementById('spnShoppingCartPrice').innerText = '₺ ' + 0;
        setItems([])
    }

    const undoDelete = () => {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setCookie('CartItems', cookies.CartItemsBackup, { path: '/', expires: tomorrow });
        setCookie('CartItemsBackup', '', { path: '/' });
        let jsonCartItems = cookies.CartItemsBackup ? cookies.CartItemsBackup : JSON.parse('[]')
        document.getElementById('spnShoppingCartCount').innerText = jsonCartItems.reduce((sum, x) => sum + x.count, 0);
        document.getElementById('spnShoppingCartPrice').innerText = '₺ ' + parseFloat(jsonCartItems.reduce((sum, x) => sum + x.count * x.price, 0)).toFixed(0);
    }

    return (
        <Fragment>
            <ModalMessage ref={modalMessageRef} />
            <MDBModalHeader className="rounded-zolfa-top-right bg-light rounded-zolfa-top-left" toggle={closeCartModal}>
                {step != "result" ?
                    <MDBRow>
                        <MDBCol className='text-center'>

                            <h4 className='text-black m-0 eqpro'> <i className={`pr-1 fa ${step == "cart" ? "fa-shopping-basket" : (type == "paket" ?(IsSignedIn ? ("fa-shopping-bag"):("fa fa-user")) : "fa-utensils")} text-black-50`}></i>{step == "cart" ? t("Sepetim") : (type == "paket" ? (IsSignedIn ?t("Paket Servis"):t("Giriş yap/Kayıt ol")) : t("Masa"))}</h4>
                            <span className='small text-black-50 eqpro'>{step == "cart" ? "" : (type == "paket" ? "" : t("Lütfen masa numaranızı seçin"))}</span>
                        </MDBCol>
                    </MDBRow>
                    :
                    <></>
                }

            </MDBModalHeader>
            <MDBModalBody className='rounded-zolfa-bottom-right rounded-zolfa-bottom-left p-0 bg-white'>

                <div className="form-cascading modal-notify rtl text-center" style={{ display: "none" }} id="stepResult">


                    <MDBIcon icon="check" size="4x" className="animated rotateIn text-success" />
                    <div>
                        <h4 className='text-success'>{t("Başarılı")}</h4>
                        {/* <p>با تشکر سفارش شما دریافت شد و به زودی ارسال می شود.</p> */}
                        <p className='text-black'>{t("Siparisiniz icin Teşekkürler,Birazdan Gönderiyoruz")}</p>
                    </div>

                    <div className="text-center px-2 d-flex justify-content-between py-3 bg-light rounded-zolfa-bottom-right rounded-zolfa-bottom-left">
                        <span className="btn btn-danger-c btn-rounded mx-0" onClick={closeCartModal}>{t("Kapat")}</span>
                        {type == "paket" ?
                            <Fragment>
                                {/* <a target="_blank" href="tel:02123463846" onClick={closeConfimModal} className="btn btn-success btn-sm btn-rounded">تماس تلفنی <br />Call</a> */}
                                <a target="_blank" href={whatsappLink} className="btn btn-orange btn-rounded mx-0">Whatsapp</a>
                            </Fragment>
                            : null
                        }
                    </div>
                </div>

                <div id="stepOrder" className="form-cascading  mt-2 modal-notify rtl " style={{ display: 'none' }}  >


                    <MDBRow className="m-0">

                        <MDBCol className="" size="12">
                            <MDBRow className="justify-content-center">
                                {type == "salon" ?
                                    <Fragment>
                                        <MDBCol size="12" className="text-center mb-2 ltr">
                                            {Settings.table}
                                        </MDBCol>
                                        <MDBCol size="12" className="text-center mb-3">
                                            <MDBRow className="justify-content-center ltr m-0 mb-2">
                                                {[...Array(Settings.tableCount)].map((x, i) =>
                                                    <MDBCol size='3' onClick={() => setTable(i + 1)} className={`c-pointer px-1 mt-2 text-center`}><div style={{ borderRadius: '10px' }} className={`border py-2 small ${table == (i + 1) ? 'bg-success text-white' : 'text-black'}`}><i className={`pr-1 fa ${table == (i + 1) ? 'fa-check' : 'fa-chair'}`}></i> {t("Masa")} - {(i + 1)}</div></MDBCol>
                                                )}
                                            </MDBRow>

                                        </MDBCol>
                                    </Fragment>
                                    :
                                    <MDBCol size="12" className="text-center mb-2 ltr">

                                    </MDBCol>
                                }
                                {type == "paket" ?
                                    IsSignedIn ?
                                        <MDBCol size='12' className='py-2'>
                                            <MyAddress shoppingCart={true} />
                                            <p style={{display:'none'}} id="cartAddress" className='m-0 ltr text-left'>{description}</p>
                                            <span style={{display:'none'}} id='cartKat'>{kat}</span>
                                            <span style={{display:'none'}} id='cartDaire'>{daire}</span>
                                            <span style={{display:'none'}} id='cartBlok'>{blok}</span>
                                            <span style={{display:'none'}} id='cartFirstName'>{name}</span>
                                            <span style={{display:'none'}} id='cartLastName'>{lastName}</span>
                                        </MDBCol>
                                        :
                                        <Login shoppingCart={true} />
                                    :
                                    null
                                }
                                {type == "paket" ?
                                    IsSignedIn ?
                                  <MDBCol className="" size="12">
                                                        <textarea onChange={(e) => setUserNote(e.target.value)} placeholder={t("Not") + ' :'} className="form-control ltr placeholder" id="UserNote" style={{ resize: 'none' }} value={userNote} rows="2"></textarea>
                                                    </MDBCol>
                                                    :null
                                                    :
                                                    <MDBCol className="" size="12">
                                                    <textarea onChange={(e) => setUserNote(e.target.value)} placeholder={t("Not") + ' :'} className="form-control ltr placeholder" id="UserNote" style={{ resize: 'none' }} value={userNote} rows="2"></textarea>
                                                </MDBCol>
}
                                {type == "paket" ?
                                    IsSignedIn ?
                                        <MDBCol className="pb-3 pt-3 text-left" size="12">

                                            <span className="mx-0 text-black"> {t("Servis (plastik çatal , peçete) ISTEMIYORUM")}</span><span onClick={() => { setNoSpoon(!noSpoon) }} className="c-pointer "><i style={{ fontSize: '20px' }} className={`checkbox-circle ${noSpoon ? 'fa fa-circle orange-text' : 'far fa-circle text-black'}`}></i></span>
                                        </MDBCol>
                                        :
                                        null
                                    :
                                    <MDBCol className="pb-3 pt-3 text-left " size="12">

                                        <span className=" text-black mx-0"> {t("Paket")}</span><span onClick={() => { setPacketSalon(!packetSalon) }} className="c-pointer "><i style={{ fontSize: '20px' }} className={`checkbox-circle ${packetSalon ? 'fa fa-circle orange-text' : 'far fa-circle text-black'}`}></i></span>
                                    </MDBCol>
                                }
                                <MDBCol size="12" className="text-center mt-3">
                                    <span className="text-danger font-weight-bold">
                                        {mobileError}
                                    </span>
                                </MDBCol>
                                {type == "salon" || (type == "paket" && IsSignedIn) ?
                                    <MDBCol size="12" className='d-flex justify-content-between bg-light rounded-zolfa-bottom-right rounded-zolfa-bottom-left py-3'>

                                        <span onClick={submitOrder} className={`mx-0 btn btn-rounded btn-orange  ${submitLoading ? 'disabled' : ' '}`}>{submitLoading ? <i class="fa fa-spin fa-spinner"></i> :
                                            <div className="row m-0">

                                                <div className="col-12 p-0" style={{ textTransform: 'none' }}>{t("Siparişi Onayla")} <i className='fa fa-check pr-1'></i> </div></div>
                                        }</span>

                                        <span onClick={closeCartModal} className="mx-0 btn btn-danger-c btn-rounded">  <div className="row m-0">

                                            <div className="col-12 p-0" style={{ textTransform: 'none' }}> {t("iptal")} <i className='fa fa-times pr-1'></i></div>
                                        </div></span>

                                    </MDBCol>
                                    :
                                    null
                                }


                            </MDBRow>
                        </MDBCol>


                    </MDBRow>

                </div>
                <div id="stepCart" className="rtl px-0">
                    {(FoodsLoading || !Settings) ? <MDBCol size="12" className="text-center"> <div className="mt-2"><Loading /></div></MDBCol> :
                        items.length > 0 ?
                            <MDBRow className="m-0">
                                <MDBCol size='12' className="  position-relative px-0">
                                    <div className=" w-100  pl-3 pl-md-0" style={{ left: 0, top: 0 }}>
                                        <span onClick={deleteAll} className="btn btn-rounded btn-danger-c btn-sm">{t("Hepsini Sil")} <i className='fa fa-times pr-1'></i></span>
                                        {items.map((item, i) =>
                                            <FoodCart size="12" renderNumberClass='' key={i} {...item} />
                                        )}

                                    </div>

                                </MDBCol>
                                <MDBCol size='12' className=" text-right py-2" >
                                    <span className="font-weight-bold text-black" style={{ 'font-size': '1.2em' }}>{t("Toplam")} =</span><span className="h5 font-weight-bold text-black" style={{ 'font-size': '1.5em' }}> {total}<span className="tl-font">₺</span></span>
                                </MDBCol>

                            </MDBRow>
                            :
                            <div className="text-center my-3 mx-3" >

                                <span className='text-black-50'>{t("Herhangi Bir Ürün Bulunmuyor")}</span>
                                <br />

                                <br />
                                <MDBRow>
                                    {cookies.CartItemsBackup ?
                                        <MDBCol className="text-left">
                                            <MDBBtn color="success" onClick={undoDelete} className=" btn-rounded">{t("Geri alma")}</MDBBtn>
                                        </MDBCol>
                                        :
                                        null
                                    }
                                    <MDBCol className={cookies.CartItemsBackup ? 'text-right' : 'text-center'}>
                                        <MDBBtn color='orange' onClick={closeCartModal} className="btn-rounded">{t("Menü")}</MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                    }
                    <div id="divOrder" className="row m-0 bg-light rounded-zolfa-bottom-right rounded-zolfa-bottom-left">
                        {items.length > 0 ?
                            <Fragment>
                                <div className="text-center col-12" >

                                    {Settings ?
                                        Settings.disablePaket ?
                                            <p className='text-danger m-0 pt-3'>{t("Şu anda kalabalık nedeniyle dışarıdaki siparişler aktıf değil, lütfen birkaç dakika sonra tekrar gelin")}.</p>
                                            :
                                            null
                                        :
                                        null

                                    }

                                    {
                                        Settings ?
                                            ((total < Settings.minOrder)) ?
                                                <p className='m-0 pt-3'>
                                                    <span className="text-danger font-weight-bold">
                                                        {t("Asgari")} :
                                                    </span>
                                                    <span className="text-danger mr-2 font-weight-bold">
                                                        {Settings.minOrder}<span style={{ marginLeft: '2px' }}>TL</span>
                                                    </span>
                                                </p>
                                                : null
                                            :
                                            null
                                    }
                                    {
                                        Settings ?
                                            Settings.active == false ?
                                                <p className="text-danger font-weight-bold m-0 pt-3">
                                                    {Settings.hoursOfWorkCaption}
                                                </p>
                                                : null
                                            : null
                                    }




                                </div>
                                {Settings ?
                                    ((!Settings.disableSalon) || (!Settings.disablePaket)) ?
                                        <MDBCol size='12' className='py-3 d-flex justify-content-between'>

                                            {((!Settings.disablePaket) && (!queryParam.table))?
                                                <Fragment>
                                                    {/* <span onClick={() => setType('paket')} className="c-pointer"><i style={{ fontSize: '30px' }} className={`${type == 'paket' ? 'far fa-check-square text-success' : 'far fa-square'}`}></i></span><div onClick={() => setType('paket')} className="mr-2 pb-1 font-weight-bold">پاکت سرویس/PAKET</div> */}
                                                    <MDBBtn disabled={(((!Settings) || (type == null) || (Settings.active == false)) || ((!Settings) || (total < Settings.minOrder) || (userCountry == "Iran"))) ? true : false} color="orange" className=" btn-rounded mx-0"
                                                        onClick={() => {
                                                            setType('paket')
                                                            setTimeout(() => {
                                                                stepOrder()
                                                            }, 500);
                                                        }
                                                        }>
                                                        {(!queryParam.table)?t("Tamam"):t("Paket Servis")}
                                                        <i className='fa fa-shopping-bag pr-1'></i>
                                                    </MDBBtn>
                                                </Fragment>
                                                :
                                                null
                                            }
                                            {((!Settings.disableSalon) && (queryParam.table)) ?
                                                <Fragment>
                                                    {/* <span onClick={() => setType('salon')} className="c-pointer"><i style={{ fontSize: '30px' }} className={`${type == 'salon' ? 'far fa-check-square text-success' : 'far fa-square'}`}></i></span><div onClick={() => setType('salon')} className="mr-2 pb-1 font-weight-bold">سالن/SALON</div> */}
                                                    <MDBBtn disabled={(((!Settings) || (type == null) || (Settings.active == false)) || (userCountry == "Iran")) ? true : false} color="orange" className="btn-rounded mx-0"
                                                        onClick={() => {
                                                            setType('salon')
                                                            setTimeout(() => {
                                                                if(queryParam.table){
                                                                    submitOrder() 
                                                             
                                                                }else{
                                                                    stepOrder()
                                                                }
                                                            }, 500);
                                                        }
                                                        }>
                                                        {(queryParam.table)?t("Tamam"):t("SALON")}
                                                        <i className={`fa ${(queryParam.table)?'fa-check':'fa-chair'} pr-1`}></i>
                                                    </MDBBtn>
                                                </Fragment>
                                                : null
                                            }

                                        </MDBCol>
                                        :
                                        null
                                    :
                                    null
                                }


                            </Fragment>
                            : null
                        }
                    </div>
                </div>

            </MDBModalBody>

        </Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        GetFoodList: () => dispatch(GetFoodList()),
        GetSetting: () => dispatch(GetSetting())
    }
}
const mapStateToProps = (state, props) => {
    return {
        Foods: state.Foods.Data,
        FoodsLoading: state.Foods.Loading,
        Settings: state.Settings.Data,
        IsSignedIn: state.IsSignedIn.Value,
        CurrentProfile: state.CurrentProfile
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCart))

