import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'

import "./index.css";

const InputNumber = forwardRef((props, ref) => {
    let [value, setValue] = useState(props.default ? props.default : 0);

    const decrease = () => {
        if (value > 0) {
            setValue(value - 1)
            props.addToCart({
                id: props.foodID,
                price: props.price,
                count: -1
            });
        } else {
            setValue(0)
        }
    }

    const increase = () => {
        setValue(value + 1)
        props.addToCart({
            id: props.foodID,
            price: props.price,
            count: 1
        });
    }

    useEffect(() => {
        setValue(props.default)

    }, [props.default])

    useImperativeHandle(ref, () => ({
        increase() {
            setValue(value + 1)
            props.addToCart({
                id: props.foodID,
                price: props.price,
                count: 1
            });
        },
        decrease() {
            if (value > 0) {
                setValue(value - 1)
                props.addToCart({
                    id: props.foodID,
                    price: props.price,
                    count: -1
                });
            } else {
                setValue(0)
            }
        }
    }));

    return (
        <div className="def-number-input number-input ltr text-center my-auto d-flex flex-row border-0  pt-md-0" >
            <span data-id={props.foodID} onClick={decrease} className='spnMinus btn-floating btn-danger-c my-0'  style={{  width: 35, height: 35 }}><i className='fa fa-minus text-black-50' style={{ 'line-height': 36, 'font-size': '1.10rem' }}></i></span>
            <span className={'spnCount  text-center white card px-2 border text-black'}>{value}</span>
            <span data-id={props.foodID} onClick={increase} className='btn-floating btn-green-c mr-0  spnPlus my-0' style={{  width: 35, height: 35 }}><i className='fa fa-plus text-black-50' style={{ 'line-height': 36, 'font-size': '1.10rem' }}></i></span>
            {/* <button onClick={decrease} className="minus text-danger"></button>
            <input className="quantity" name="quantity" value={value} onChange={() => console.log('change')} type="number" />
            <button onClick={increase} className="plus text-success"></button> */}
        </div>
    )
})

export default InputNumber
