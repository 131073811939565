import * as type from './../../actions/ActionTypes';
const initialState = {
    Data: [],
    Loading: true
}

export function Comments(state = initialState, action) {
    switch (action.type) {
        case type.Comment_List:
            return {
                ...state,
                Data: action.payload,
                Loading: false
            }      
        default:
            return state
    }
}