import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Loading from './../Loading';
import * as staticValue from './../../staticValue';
import FoodDetails from './FoodDetails';
import InputNumber from './../../MDBproComponents/InputNumber/index'
import {
    MDBCol, MDBContainer, MDBRow, MDBCarousel, MDBCarouselItem,
    MDBView, MDBCarouselInner, MDBCard, MDBCardBody
} from 'mdbreact';
const Details = (props) => {
    let { FoodsLoading, food } = props;
    useEffect(() => {
        return () => {
        }
    }, []);
    const addToCart=()=>{
        console.log(document.getElementsByClassName('quantity')[0].value)
    }
    let CreateCarouselItems = () => {
        let Result = [];
        Result.push(<MDBCarouselItem itemId={1}>
            <MDBView>
                <img
                    className="d-block w-100"
                    src={food.mainImageFileName}
                    alt={food.title}
                />
            </MDBView>
        </MDBCarouselItem>
        )
        if (food.gallery) {
            let GalleryItems = food.gallery.galleryItem.map((item, i) =>
                <MDBCarouselItem key={i + 2} itemId={i + 2}>
                    <MDBView>
                        <img
                            className="d-block w-100"
                            src={staticValue.siteFileUrl + '/' + item.file.fileID + item.file.extension}
                            alt={item.title}
                        />
                    </MDBView>
                </MDBCarouselItem>);
            Result.push(...GalleryItems)
        }
  
        return Result;
    }
    return (
        <MDBContainer>
            <MDBRow className="rtl mh-50">
                {FoodsLoading ? <MDBCol size="12" className="text-center "> <Loading /></MDBCol> :
                     <FoodDetails renderNumberClass='' size="12" {...food} />
                    // <Fragment>
                    //     <MDBCol className="pt-5 mb-md-3" size="12" md="8">
                    //         <MDBCarousel 
                    //             activeItem={1}
                    //             length={Food.gallery ? Food.gallery.galleryItem.length + 1 : 1}
                    //             showControls={true}
                    //             showIndicators={true}
                    //             className="z-depth-1 mt-md-3">
                    //             <MDBCarouselInner>
                    //                 {CreateCarouselItems()}
                    //             </MDBCarouselInner>
                    //         </MDBCarousel>
                    //     </MDBCol>
                    //     <MDBCol size="12" md="4" className="pt-md-5 " size="12">
                    //         <MDBCard className="mt-3">
                    //             <MDBCardBody className="row">
                    //                 <p>{Food.title}</p>
                    //                 <p dangerouslySetInnerHTML={{ __html: Food.material }}></p>
                    //                 <strong className='text-info'>₺{Food.price}</strong>
                    //                 <br />
                    //                 <InputNumber default={0}/>
                    //                 <span onClick={addToCart} className="btn-floating btn-info"><i className='fa fa-plus'></i></span>

                    //             </MDBCardBody>
                    //         </MDBCard>
                    //     </MDBCol>
                    // </Fragment>
                }
            </MDBRow>
        </MDBContainer>
    )
}

const mapStateToProps = (state, props) => {
    return {
        food: state.Foods.Data.find(x => (x.permalink == props.match.params.permalink)),
        FoodsLoading: state.Foods.Loading,
    }
}

export default connect(mapStateToProps)(Details)
