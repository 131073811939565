import  React,{ useRef, useState, useEffect, Fragment } from 'react';
import { MDBRow, MDBContainer, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBAnimation } from 'mdbreact';
import ProfileMenu from './ProfileMenu';
import { connect } from 'react-redux';
import ModalMessage from './../ModalMessage';
import { GetCurrentProfile } from './../../actions/profile'
import AjaxForm from './../AjaxForm';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-modern-calendar-datepicker";
import helpers from './../../helpers';
import ProfileCardHeader from './ProfileCardHeader';
import Login from './Login';
import configs from '../../config';
const ProfileInfo = (props) => {

    let { CurrentProfile,IsSignedIn } = props
    let [values, setValues] = useState({ FirstName: "", LastName: "",Email:"",Mobile:''})
    let [errors, setErrors] = useState({})
    const { t, i18n } = useTranslation();
    const modalMessageRef = useRef();
    const [BirthDate, setBirthDate] = useState(null);

    useEffect(() => {
        if( document.getElementById('navProfileProfileInfo')){
            document.getElementById('navProfileProfileInfo').classList.add("active");
        }
        document.getElementById('divHomeMenu').classList.add("d-none");
        return () => {
            if( document.getElementById('navProfileProfileInfo')){
                document.getElementById('navProfileProfileInfo').classList.remove("active");
            }
            document.getElementById('divHomeMenu').classList.remove("d-none");
        }
    }, [IsSignedIn])
    

    useEffect(() => {
        if (CurrentProfile) {
            setValues({
                FirstName: CurrentProfile.firstName,
                LastName: CurrentProfile.lastName,
                Email: CurrentProfile.email,
                Mobile: CurrentProfile.mobile,
            })

        }
    }, [CurrentProfile]);

    const beforeSubmit = () => {
        const ValidateErrors = handleValidate();
        setErrors(ValidateErrors)
        if (Object.keys(ValidateErrors).length > 0) {
            return false;
        }
    }

    const handleValidate = () => {
        let errors = {};
        if (!values.FirstName) {
            errors.FirstName = t('İsim gerekli');
        }
        if (!values.LastName) {
            errors.LastName = t('soyadı gerekli');
        }
        return errors;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    const Done = (data) => {
        modalMessageRef.current.toggle("Data has been saved.", "", null, "success", "check")
        props.GetCurrentProfile()
    }

    const Fail = (data) => {
        modalMessageRef.current.toggle(data, 'Error', null, 'danger', 'exclamation')
    }


    return (
        <MDBContainer>
            <ModalMessage ref={modalMessageRef} />
            <MDBRow className="pt-2 pt-md-5 mb-5 ltr text-left justify-content-center">
                {IsSignedIn?
                <div className=" row col-12 mx-0 mt-3">
                    <ProfileMenu />
                    <MDBCol size="12" md="8" lg='9' className='p-0 my-4'>
                    <MDBAnimation reveal type="fadeIn" duration="1s" delay="0.2s">
                        <MDBCard narrow className='mb-2 row mx-md-0'>
                            <ProfileCardHeader title={t("Profil")}></ProfileCardHeader>
                            <MDBCardBody cascade className=''>
                                <AjaxForm id='AFUpdate' api={configs.siteApiUrl+'/api/user/Update'} onFail={Fail} onSuccess={Done} beforeSubmit={beforeSubmit}>
                                    <div className="form-grou col-12 col-md-6 mt-2">
                                        <label for="FirstName" className="form-group-addon">{t("Ad")}</label>
                                        <input name="FirstName" onChange={handleChange} type="text" value={values.FirstName} className="form-control" />
                                        {errors.FirstName && <p className='text-danger'>{errors.FirstName}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 mt-2">
                                        <label for="LastName" className="form-group-addon">{t("Soyad")}</label>
                                        <input name="LastName" onChange={handleChange} type="text" value={values.LastName} className="form-control" />
                                        {errors.LastName && <p className='text-danger'>{errors.LastName}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 mt-2">
                                        <label for="Email" className="form-group-addon">Email</label>
                                        <input  type="email" name='Email' value={values.Email} className="form-control" />
                                    </div>
                                
                                    <MDBCol size='12' className='text-right d-flex justify-content-between align-items-center'>
                                     <span>{values.Mobile}</span>
                                                <button type='submit' className="btn btn-success btn-rounded m-0">{t("Kaydet")}</button>
                                    
                                    </MDBCol>
                                </AjaxForm>
                            </MDBCardBody>
                        </MDBCard>
            </MDBAnimation>
                    </MDBCol>
                </div>
                :
             <MDBCol size='12' md='6' className='card my-2 my-md-5'>
                   <Login shoppingCart={true} />
             </MDBCol>
            }
            </MDBRow>
        </MDBContainer>
    )
}
const mapStateToProps = state => {
    return {
        CurrentProfile: state.CurrentProfile,
        IsSignedIn: state.IsSignedIn.Value
    }
}
const mapDispatchToProps = dispatch => {
    return {
        GetCurrentProfile: () => dispatch(GetCurrentProfile()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo) 
