import 'bootstrap/dist/css/bootstrap.css';
import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';

import Store from './store/index';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import "./MDBproComponents.css";
import './i18next';

import registerServiceWorker from './registerServiceWorker';
import { CookiesProvider } from 'react-cookie'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={Store}>
        <Suspense fallback={(<div className="row"><div className="col-12 text-center mt-5 "><div style={{width:"3rem",height:"3rem"}} className="spinner-border text-info mt-5 " role="status">
                                    <span className="sr-only">Loading...</span>
            </div></div></div>)} >
           <CookiesProvider>
                <App />
           </CookiesProvider>
            </Suspense>
        </Provider>
    </BrowserRouter>,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
registerServiceWorker();

