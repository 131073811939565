import React, { useEffect, useState, useRef } from 'react'
import { MDBCard, MDBCardBody, MDBCol } from 'mdbreact'
import Row from '../../MDBproComponents/Row/Row'
import Link from '../../MDBproComponents/Link/Link'
import { useCookies } from 'react-cookie';
import InputNumber from '../../MDBproComponents/InputNumber';

const FoodDetails = (props) => {
    const numberInput = useRef();
    const [cookies, setCookie] = useCookies();
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (cookies.CartItems.find(x => x.id == props.foodID)) {
            setCount(cookies.CartItems.find(x => x.id == props.foodID).count)
        }
    })

    function addToCart(item) {
        let jsonCartItems = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')

        if (jsonCartItems.find(x => x.id == item.id)) {
            jsonCartItems.find(x => x.id == item.id).count = jsonCartItems.find(x => x.id == item.id).count + item.count;
            if (jsonCartItems.find(x => x.id == item.id).count == 0) {
                jsonCartItems = jsonCartItems.filter(function (x) { return x.id !== item.id })
            }
        } else {
            jsonCartItems.push(item)
        }
        setCookie('CartItems', JSON.stringify(jsonCartItems), { path: '/' });
        document.getElementById('spnShoppingCartCount').innerText = jsonCartItems.reduce((sum, x) => sum + x.count, 0);
        document.getElementById('spnShoppingCartPrice').innerText = '₺ ' + parseFloat(jsonCartItems.reduce((sum, x) => sum + x.count * x.price, 0)).toFixed(0);
    }

    // Animate Image to the Shopping Cart
    function AnimateToShoppingCart(e) {
        let el = e.currentTarget;
        let item = el.parentElement;
        let img = item.querySelector('.imgFood');
        let cartTopOffset = document.getElementById('btnShoppingCart').getBoundingClientRect().top - item.getBoundingClientRect().top;
        let cartLeftOffset = document.getElementById('btnShoppingCart').getBoundingClientRect().left - item.getBoundingClientRect().left;
        var flyingImg = new Image(200, 150);
        flyingImg.setAttribute('src', img.getAttribute('src'));
        flyingImg.setAttribute('class', 'imgFlying');
        el.parentElement.append(flyingImg);
        flyingImg.animate({
            top: cartTopOffset + 'px',
            left: cartLeftOffset + 'px',
            width: '50px',
            height: '50px',
            opacity: 0.4,
        }, 800);

        numberInput.current.increase();

        flyingImg.onanimationend = () => {
            flyingImg.remove();
        };
    }

    return (
        <MDBCol lg={props.size ? props.size : '6'} className='mb-4'>
            <MDBCard className=' rtl'>
                <MDBCardBody className='p-0'>
                    <Row className='flex-row-reverse'>
                        <MDBCol size='12' md='4'>
                            <Link to={`/Food/${props.permalink}`} className='p-0 overflow-hidden'>
                                <img className='img-fluid imgFood rounded-top' src={props.mainImageFileName} alt={props.Title} />
                            </Link>
                        </MDBCol>
                        <MDBCol size='12' md='8' className=''>
                            <Row className='position-relative rtl h-100'>
                                <MDBCol size="12" className='px-1'>
                                    <Link to={`/Food/${props.permalink}`} className='black-text font-weight-bold' style={{ 'font-size': '0.8em' }}>
                                        <span className='FoodTitle px-1'>{props.title}</span>/<span className='FoodTitle px-1'>{props.titleTR}</span>
                                    </Link>
                                </MDBCol>
                                <MDBCol size='12' className='ltr text-left mb-3 px-4 grey-text' style={{ 'font-size': '0.7em' }}>
                                    <p dangerouslySetInnerHTML={{ __html: props.material }}></p>
                                </MDBCol>
                                <MDBCol size='4' className="d-none d-md-block px-4">
                                    <InputNumber ref={numberInput} addToCart={addToCart} price={props.price} foodID={props.foodID} default={count} />
                                </MDBCol>
                                <strong className=' black-text position-absolute tl-font' style={{ 'bottom': '0px', 'left': '25px', 'font-size': '1.3em' }}>₺{props.price}</strong>
                            </Row>
                        </MDBCol>
                    </Row>
                </MDBCardBody>
                <MDBCol size='6' className="d-md-none mb-4 text-center mx-auto">
                    <InputNumber ref={numberInput} addToCart={addToCart} price={props.price} foodID={props.foodID} default={count} />
                </MDBCol>
                <span data-id={props.foodID} onClick={(e) => AnimateToShoppingCart(e)} className='btn-floating btn-success position-absolute' style={{ 'bottom': '-30px', 'right': '15px', width: 35, height: 35 }}><i className='fa fa-plus' style={{'line-height': 36,'font-size': '1.10rem'}}></i></span>
            </MDBCard>
        </MDBCol>
    )
}

export default FoodDetails
