import  React,{ Fragment, useState } from 'react'

import { connect } from 'react-redux';
// import ImageUpload from './../Utility/FileUpload/Image';
import AjaxForm from './../AjaxForm';
import config from './../../config';
import { useCookies } from 'react-cookie';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styles from './ProfileMenu.module.css'
import { GetCurrentProfile } from './../../actions/profile'
import {
    MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBIcon, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBCardHeader
} from 'mdbreact';
const ProfileMenu = (props) => {
    const [cookies, setCookie] = useCookies();
    let { CurrentProfile } = props;
    const { t, i18n } = useTranslation();
    let [profileModal, setProfileModal] = useState(false)

    const toggleModal = () => {
        setProfileModal(!profileModal)
    }

    const Done = () => {
        toggleModal()
        props.GetCurrentProfile(cookies.AccessToken);
    }

    const Fail = () => {

    }
    const beforeSubmit = () => {

    }
const logout=()=>{
    setCookie('AccessToken', '', { path: '/' });
    document.getElementById("divlogo").click();
}
    return (
        <Fragment>
       
            <div id="divProfileMenu" className=" col-12 col-md-4 col-lg-3 mt-n1 px-0 px-md-3 pt-md-5">
    
                <MDBCard className={`${styles.profileCard} profile-card pt-0`}>
            
                    <MDBCardBody className='p-0'>
                        <ul id={styles.ulProfileMenu} className={`list-group p-0 mt-0`}>
                        <li id='navProfileHome' className={`${styles.listGroupItem} profileListGroupItem list-group-item p-0`}>
                            <Link to='/'>
                                <a className='d-block p-0 text-dark'>
                                   <a>
                                   <span className='btn btn-sm btn-floating btn-light'>
                                        <MDBIcon className='orange-text' icon='arrow-left'></MDBIcon>
                                    </span>
                                     {t("Menü")}
                                   </a>
                                     </a>
                            </Link>
                            </li>
                            <li id='navProfileProfileInfo' className={`${styles.listGroupItem} profileListGroupItem list-group-item p-0`}>
                            <Link to='/Profile'>
                                <a className='d-block p-0 text-dark'>
                                   <a>
                                   <span className='btn btn-sm btn-floating btn-light'>
                                        <MDBIcon  className='orange-text' icon='user'></MDBIcon>
                                    </span>
                                     {t("Profil")}
                                   </a>
                                     </a>
                            </Link>
                            </li>
                            <li id='navProfileOrders' className={`${styles.listGroupItem} profileListGroupItem list-group-item p-0`}>
                            <Link to='/Orders'>
                                <a className='d-block p-0 text-dark'>
                                 <a>
                                 <span className='btn btn-sm btn-floating btn-light'>
                                        <MDBIcon className='orange-text' icon='shopping-cart'></MDBIcon>
                                    </span>
                                   {t("Sepetim")}
                                 </a>
                                    </a>
                                    </Link>
                            </li>
                            <li id='navProfileAddress' className={`${styles.listGroupItem} profileListGroupItem list-group-item p-0`}>
                            <Link to='/MyAddress'>
                                <a className='d-block p-0 text-dark'>
                                 <a>
                                 <span className='btn btn-sm btn-floating btn-light'>
                                        <MDBIcon className='orange-text' icon='map-marked'></MDBIcon>
                                    </span>
                                   {t("Benim adresim")}
                                 </a>
                                    </a>
                                    </Link>
                            </li>
                            <li id='navProfileLogOut' onClick={logout} className={`${styles.listGroupItem} profileListGroupItem list-group-item p-0`}>
                          
                                <span className='d-block p-0 text-dark c-pointer'>
                                 <span>
                                 <span className='btn btn-sm btn-floating btn-light'>
                                        <MDBIcon className='orange-text' icon='sign-out-alt'></MDBIcon>
                                    </span>
                                   {t("Çıkış Yap")}
                                 </span>
                                    </span>
                                  
                            </li>
                        </ul>
                    </MDBCardBody>
                </MDBCard>
            </div>
   
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        CurrentProfile: state.CurrentProfile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        GetCurrentProfile:(token)=>dispatch(GetCurrentProfile(token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu) 
