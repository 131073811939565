import React, { useRef, useState, useEffect, Fragment } from 'react';
import { MDBRow, MDBContainer, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBAnimation, MDBTable, MDBTableHead, MDBTableBody ,MDBModal,MDBModalHeader,MDBModalBody} from 'mdbreact';
import ProfileMenu from './ProfileMenu';
import { connect } from 'react-redux';
import ModalMessage from './../ModalMessage';
import { GetCurrentProfile } from './../../actions/profile';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import AjaxForm from './../AjaxForm';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-modern-calendar-datepicker";
import helpers from './../../helpers';
import ProfileCardHeader from './ProfileCardHeader';
import Login from './Login';
import configs from '../../config';
import MyAddress from './MyAddress';
const ProfileAddresses = (props) => {
    const moment = require('moment');
    const [cookies, setCookie] = useCookies();
    let [orderModal, setOrderModal] = useState(false)
    let { CurrentProfile, IsSignedIn } = props
    let [orders, setOrders] = useState([])
    let [order, setOrder] = useState();
    const { t, i18n } = useTranslation();
    const modalMessageRef = useRef();
    useEffect(() => {
        if (document.getElementById('navProfileAddress')) {
            document.getElementById('navProfileAddress').classList.add("active");
        }
        document.getElementById('divHomeMenu').classList.add("d-none");
        return () => {
            if (document.getElementById('navProfileAddress')) {
                document.getElementById('navProfileAddress').classList.remove("active");
            }
            document.getElementById('divHomeMenu').classList.remove("d-none");
        }
    }, [IsSignedIn])


    useEffect(() => {
        if (orders.length == 0) {
            let formdata = new FormData();
            formdata.append("SubscriptionID", configs.subscriptionID)
            axios.post(configs.siteApiUrl + '/api/Food/MyOrders', formdata, {
                headers: !cookies.AccessToken ? {} : { 'Authorization': `Bearer ${cookies.AccessToken}` }
            })
                .then(function (response) {
                    setOrders(response.data)
                });
        }
        return () => {
            setOrders([])
        }
    }, [])

    const closeOrderModal = () => {
        setOrderModal(false)
    }

    const showDetails = (orderID) => {
        setOrder()
        setOrder(orders.find(x => x.orderID === orderID))
        setOrderModal(true)
    }



    return (
        <MDBContainer>
            <ModalMessage ref={modalMessageRef} />
            <MDBRow className="pt-2 pt-md-5 mb-5 ltr text-left justify-content-center">
                {IsSignedIn ?
                    <div className=" row col-12 mx-0 mt-3">
                        <ProfileMenu />
                        <MDBCol size="12" md="8" lg='9' className='p-0 my-4'>
                            <MDBAnimation reveal type="fadeIn" duration="1s" delay="0.2s">
                                <MDBCard narrow className='mb-2 row mx-md-0'>
                                    <ProfileCardHeader title={t("Benim adresim")}></ProfileCardHeader>
                                    <MDBCardBody cascade className=''>
                                   <MyAddress />
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBAnimation>
                        </MDBCol>
                       
                    </div>
                    :
                    <MDBCol size='12' md='6' className='card my-2 my-md-5'>
                        <Login shoppingCart={true} />
                    </MDBCol>
                }
            </MDBRow>

        </MDBContainer>
    )
}
const mapStateToProps = state => {
    return {
        CurrentProfile: state.CurrentProfile,
        IsSignedIn: state.IsSignedIn.Value
    }
}
const mapDispatchToProps = dispatch => {
    return {
        GetCurrentProfile: () => dispatch(GetCurrentProfile()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileAddresses) 
