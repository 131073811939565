import React, { useEffect, useState, useRef, Fragment } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact'
import Row from '../../MDBproComponents/Row/Row'
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import InputNumber from '../../MDBproComponents/InputNumber';
import Lightbox from 'react-image-lightbox';
import { useTranslation } from 'react-i18next';
import $ from "jquery";
const FoodCart = (props) => {
    let { SettingsLoading, Settings } = props
    const { t, i18n } = useTranslation();
    const numberInput = useRef();
    const spnCount = useRef();
    const spnMinus = useRef();
    const spnPlus=useRef();
    const spnAdd=useRef();
    const [cookies, setCookie] = useCookies();
    const [count, setCount] = useState(0);
    let [title, setTitle] = useState(props.title);
    let [material, setMaterial] = useState("");
    let [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        changeLang()
        if ((cookies.CartItems??[]).find(x => x.id == props.foodID)) {
            setCount(cookies.CartItems.find(x => x.id == props.foodID).count);
            spnAdd.current.classList.add('d-none');
            spnMinus.current.classList.remove('d-none');
            spnPlus.current.classList.remove('d-none');
            // spnMinus.current.style.right = '85px';
            spnCount.current.classList.remove('d-none');
            spnCount.current.style.width = '50px';
        } else {
            spnMinus.current.classList.add('d-none');
            spnCount.current.classList.add('d-none');
            spnPlus.current.classList.add('d-none');
            spnAdd.current.classList.remove('d-none');
        }

        if (Settings) {
            if (!Settings.restaurantStatus) {
                spnMinus.current.classList.add('d-none');
                spnCount.current.classList.add('d-none');
                spnPlus.current.classList.add('d-none');
                spnAdd.current.classList.add('d-none');
            }
        }
    })
    function addToCart(item) {
        let jsonCartItems = cookies.CartItems ? cookies.CartItems : JSON.parse('[]')

        if (jsonCartItems.find(x => x.id == item.id)) {
            jsonCartItems.find(x => x.id == item.id).count = jsonCartItems.find(x => x.id == item.id).count + item.count;
            if (jsonCartItems.find(x => x.id == item.id).count == 0) {
                jsonCartItems = jsonCartItems.filter(function (x) { return x.id !== item.id })
            }
        } else {
            jsonCartItems.push(item)
        }
        setCookie('CartItems', JSON.stringify(jsonCartItems), { path: '/' });
        document.getElementById('spnShoppingCartCount').innerText = jsonCartItems.reduce((sum, x) => sum + x.count, 0);
        document.getElementById('spnShoppingCartPrice').innerText = '₺ ' + parseFloat(jsonCartItems.reduce((sum, x) => sum + x.count * x.price, 0)).toFixed(0);
    }
    const changeLang = () => {
        if (i18n.language == "tr") {
            setTitle(props.title)
            setMaterial(props.material)
        }
        if (i18n.language == "en") {
            setTitle(props.titleEN)
            setMaterial(props.materialEN)
        }
        if (i18n.language == "ar") {
            setTitle(props.titleAR)
            setMaterial(props.materialAR)
        }
    }
    // Animate Image to the Shopping Cart
    const AnimateToShoppingCart = (e) => {
        // spnCount.current.style.opacity = 0;

        let el = e.currentTarget;
        let item = el.parentElement.parentElement.parentElement.parentElement;
        let img = item.querySelector('.foodImage');
        let cartTopOffset = document.getElementById('btnShoppingCart').getBoundingClientRect().top - item.getBoundingClientRect().top;
        let cartLeftOffset = document.getElementById('btnShoppingCart').getBoundingClientRect().left - item.getBoundingClientRect().left;
        var flyingImg = new Image(200, 200);
        flyingImg.setAttribute('src', img.getAttribute('data-img'));
        flyingImg.setAttribute('class', 'imgFlying');
        el.parentElement.parentElement.parentElement.parentElement.append(flyingImg);

        $('.imgFlying').animate({
            top: 0,
            left: 0,
        }, 10, function () {
            $('.imgFlying').animate({
                top: cartTopOffset,
                left: cartLeftOffset,
                width: '30px',
                height: '30px',
                opacity: 0.4
            }, 1500, function () {
                flyingImg.remove();
            })
            setTimeout(() => {
                $('#btnShoppingCart span.py-2').animate({
                    'font-size': '1rem'
                }, 200, function () {
                    $('#btnShoppingCart span.py-2').animate({
                        'font-size': '0.81rem'
                    }, 200)
                })
            }, 1200);
        })
        // flyingImg.animate({
        //     top: cartTopOffset + 'px',
        //     left: cartLeftOffset + 'px',
        //     width: '50px',
        //     height: '50px',
        //     opacity: 0.4,
        // }, 800);

        numberInput.current.increase();

        // flyingImg.onanimationend = () => {
        //     flyingImg.remove();
        // };
        if (count === 0) {
            spnCount.current.classList.remove('d-none')
            spnCount.current.classList.add("spnCountAnimate")
            spnMinus.current.classList.remove('d-none')
            spnMinus.current.classList.add("spnMinusAnimate")
            spnPlus.current.classList.add("spnMinusAnimate")
        }
        spnAdd.current.classList.add('d-none')
    }

    function DecreaseFromCart() {
        if (count == 1) {
            spnMinus.current.classList.add("spnMinusBackHome")
            spnPlus.current.classList.add("spnMinusBackHome")
            spnCount.current.classList.add("spnCountBack")
            setTimeout(() => {
                numberInput.current.decrease()
                setCount(count - 1)
                spnMinus.current.classList.remove("spnMinusBackHome")
                spnPlus.current.classList.remove("spnMinusBackHome")
                spnCount.current.classList.remove("spnCountBack")
            }, 500);
        } else {
            numberInput.current.decrease();
            setCount(count - 1)
        }
    }
    return (
        <Fragment>

            <MDBCol lg={props.size ? props.size : '3'} md='4' size='6' className='mb-0 pb-2 px-1 px-md-2 pb-md-3'>
                <MDBCard className='h-100 rtl border' style={{ boxShadow: 'none !important', borderRadius: '10px' }}>
                    <MDBCardBody className='p-0'>
                        <Row className='flex-row-reverse m-0 h-100'>
                            <MDBCol style={{backgroundImage:'url("'+props.mainImageFileName+'")'}} size='12' data-img={props.mainImageFileName} className='p-0 foodImage'>
                                {/* <span onClick={() => setIsOpen(true)} className='c-pointer pr-0 pl-2 pl-md-0 py-2 py-md-0 overflow-hidden nav-link Ripple-parent'>
                                    <img className='img-fluid imgFood' src={props.mainImageFileName} alt={props.Title} style={{ borderRadius: "10px 10px 0px 0px" }} />
                                </span> */}
                            </MDBCol>

                            <MDBCol data-id={props.foodID} size='12' className='pb-2 p-0 '>
                                <Row className='position-relative rtl h-100 m-0'>
                                    <MDBCol size="12" className='mt-1 d-flex flex-row justify-content-between'>
                                     
                        
                                                <p className=' text-black tl-font m-0' style={{ 'bottom': '0px', 'left': '25px', 'font-size': '0.8em' }}>₺{props.price}</p>

                                   
                                                <p className={`m-0 text-black font-weight-bold pr-1 eqpro d-block ${((i18n.language == "tr") || (i18n.language == "en")) ? 'ltr text-left' : null}`} style={{ 'font-size': '0.9em' }}>
                                                    <span className='FoodTitle px-1'>{title}</span>
                                                </p>
                                          
                                     

                                    </MDBCol>
                                    <MDBCol size='12' className={`${((i18n.language == "tr") || (i18n.language == "en")) ? 'ltr text-left' : null} mb-md-3 text-black-50`} style={{ 'font-size': '0.7em' }}>
                                        <p style={{minHeight:'19px'}} dangerouslySetInnerHTML={{ __html: material }}></p>
                                    </MDBCol>

                                </Row>
                            </MDBCol>
                            <MDBCol className={props.renderNumberClass}>
                                <InputNumber ref={numberInput} addToCart={addToCart} price={props.price} foodID={props.foodID} default={count} />
                            </MDBCol>
                            <MDBCol size='12' className='d-flex flex-row justify-content-between mb-2 align-items-end'>
                                <span ref={spnPlus} data-id={props.foodID} onClick={(e) => AnimateToShoppingCart(e)} className={`btn-floating btn-green-c m-0 spnPlus ${Settings ? Settings.closed ? 'd-none' : null : null}`} style={{  width: 40, height: 40 }}><i className='fa fa-plus text-black-50' style={{ 'line-height': 41, 'font-size': '1.10rem' }}></i></span>
                                <span ref={spnCount} style={{height:'40px'}} className={`spnCount-home flex-center text-center white border text-black card ${Settings ? (!Settings.restaurantStatus) ? 'd-none' : null : null}`}>{count}</span>
                                <span ref={spnMinus} data-id={props.foodID} onClick={(e) => DecreaseFromCart(e)} className={`spnMinus-home btn-floating m-0 btn-danger-c  ${Settings ? Settings.closed ? 'd-none' : null : null}`}><i className='fa fa-minus text-black-50' style={{ 'line-height': 41, 'font-size': '1.10rem' }}></i></span>
                                <span ref={spnAdd}  onClick={(e) => AnimateToShoppingCart(e)} className='btn btn-orange btn-block btn-rounded m-0'>{t("Ekle")}</span>
                            </MDBCol>
                        </Row>
                    </MDBCardBody>



                </MDBCard>
                {isOpen && (
                    <Lightbox
                        mainSrc={props.mainImageFileName}
                        onCloseRequest={() => setIsOpen(false)}
                        imageTitle={<p onClick={() => setIsOpen(false)} className="closeLightbox c-pointer"><i className="far fa-times-circle fa-3x"></i></p>}
                    />
                )}
            </MDBCol>

        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        Settings: state.Settings.Data,
        SettingsLoading: state.Settings.Loading
    }
}

export default connect(mapStateToProps)(FoodCart)

