import React, { useRef, useState, useEffect, Fragment } from 'react';
import { MDBRow, MDBContainer, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBAnimation, MDBTable, MDBTableHead, MDBTableBody ,MDBModal,MDBModalHeader,MDBModalBody} from 'mdbreact';
import ProfileMenu from './ProfileMenu';
import { connect } from 'react-redux';
import ModalMessage from './../ModalMessage';
import { GetCurrentProfile } from './../../actions/profile';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import AjaxForm from './../AjaxForm';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-modern-calendar-datepicker";
import helpers from './../../helpers';
import ProfileCardHeader from './ProfileCardHeader';
import Login from './Login';
import configs from '../../config';
const ProfileOrders = (props) => {
    const moment = require('moment');
    const [cookies, setCookie] = useCookies();
    let [orderModal, setOrderModal] = useState(false)
    let { CurrentProfile, IsSignedIn } = props
    let [orders, setOrders] = useState([])
    let [order, setOrder] = useState();
    const { t, i18n } = useTranslation();
    const modalMessageRef = useRef();
    useEffect(() => {
        if (document.getElementById('navProfileOrders')) {
            document.getElementById('navProfileOrders').classList.add("active");
        }
        document.getElementById('divHomeMenu').classList.add("d-none");
        return () => {
            if (document.getElementById('navProfileOrders')) {
                document.getElementById('navProfileOrders').classList.remove("active");
            }
            document.getElementById('divHomeMenu').classList.remove("d-none");
        }
    }, [IsSignedIn])


    useEffect(() => {
        if (orders.length == 0) {
            let formdata = new FormData();
            formdata.append("SubscriptionID", configs.subscriptionID)
            axios.post(configs.siteApiUrl + '/api/Food/MyOrders', formdata, {
                headers: !cookies.AccessToken ? {} : { 'Authorization': `Bearer ${cookies.AccessToken}` }
            })
                .then(function (response) {
                    setOrders(response.data)
                });
        }
        return () => {
            setOrders([])
        }
    }, [])

    const closeOrderModal = () => {
        setOrderModal(false)
    }

    const showDetails = (orderID) => {
        setOrder()
        setOrder(orders.find(x => x.orderID === orderID))
        setOrderModal(true)
    }



    return (
        <MDBContainer>
            <ModalMessage ref={modalMessageRef} />
            <MDBRow className="pt-2 pt-md-5 mb-5 ltr text-left justify-content-center">
                {IsSignedIn ?
                    <div className=" row col-12 mx-0 mt-3">
                        <ProfileMenu />
                        <MDBCol size="12" md="8" lg='9' className='p-0 my-4'>
                            <MDBAnimation reveal type="fadeIn" duration="1s" delay="0.2s">
                                <MDBCard narrow className='mb-2 row mx-md-0'>
                                    <ProfileCardHeader title={t("Sepetim")}></ProfileCardHeader>
                                    <MDBCardBody cascade className=''>
                                        {orders.length > 0 ?
                                            <MDBTable responsive className='text-black'>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{t("Tarih")}</th>
                                                        <th>{t("Sipariş durumu")}</th>
                                                        <th>{t("Toplam")}</th>
                                                        <th>{t("Detaylar")}</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {orders.map((obj, numb) =>
                                                        <tr key={numb}>
                                                            <td className='pr-0'>{numb + 1}</td>
                                                            <td>{moment.utc(obj.createDateTime).local().format("MMM D, YYYY HH:mm")}</td>
                                                            <td className="">{t(obj.status ?? "sent")}</td>
                                                            <td className="tl-font">{obj.total} ₺</td>
                                                            <td className='align-middle p-0'><span onClick={() => showDetails(obj.orderID)} className='btn btn-sm btn-floating btn-orange my-0'><i className='fa fa-eye'></i></span></td>
                                                        </tr>
                                                    )}

                                                </MDBTableBody>
                                            </MDBTable>
                                            :
                                            <p className='text-center'>{t("Ürün yok")}</p>
                                        }

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBModal className="form-cascading modal-notify ltr mt-5" size="lg" id="orderModal" isOpen={orderModal}
                            toggle={closeOrderModal}>
                            <MDBModalHeader className="rounded-zolfa-top-right bg-light rounded-zolfa-top-left align-items-center" toggle={closeOrderModal}>
                                <h5 className='text-black mb-0'>{order?moment.utc(order.createDateTime).local().format("MMM D, YYYY HH:mm"):""}</h5>
                            </MDBModalHeader>
                            <MDBModalBody className='bg-white'>

                                {order ?

                                    <MDBRow className='mb-3 rounded border py-2'>

                                        <MDBCol className="my-2 rounded " size="12">
                                            <MDBTable responsive className='text-black'>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{t("Adet")}</th>
                                                        <th>{t("Fiyat")}</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {order.orderDetails.map((obj, numb) =>
                                                        <tr key={numb}>
                                                            <td className='pr-0'>{numb + 1}-{obj.title}</td>
                                                            <td>{obj.count}</td>
                                                            <td className="tl-font">{obj.total} ₺</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td rowSpan="2"><h5 className="font-weight-bold text-black">{t("Toplam")}:</h5></td>
                                                        <td></td>
                                                        <td rowSpan="2"><h5 className="font-weight-bold tl-font text-black">{order.total} ₺</h5></td>
                                                    </tr>
                                                </MDBTableBody>
                                            </MDBTable>
                                        </MDBCol>
                                    </MDBRow>
                                    : null
                                }
                            </MDBModalBody>

                        </MDBModal>
                    </div>
                    :
                    <MDBCol size='12' md='6' className='card my-2 my-md-5'>
                        <Login shoppingCart={true} />
                    </MDBCol>
                }
            </MDBRow>

        </MDBContainer>
    )
}
const mapStateToProps = state => {
    return {
        CurrentProfile: state.CurrentProfile,
        IsSignedIn: state.IsSignedIn.Value
    }
}
const mapDispatchToProps = dispatch => {
    return {
        GetCurrentProfile: () => dispatch(GetCurrentProfile()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileOrders) 
