import { combineReducers } from 'redux';
import { CurrentProfile, IsSignedIn ,AccessToken,CurrentProfileRoles} from './profile/index';
import {Comments} from './Comment/index'
import {Foods, ShoppingCart,Types} from './Food/index'
import {Settings,Search} from './Website'
const MainReducer = combineReducers({
    CurrentProfile,
    IsSignedIn,
    CurrentProfileRoles,
    AccessToken,
    Comments,
    Foods,
    Types,
    ShoppingCart,
    Settings,
    Search
})

export default MainReducer;